import React from "react";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import PleaseWait from "../../atoms/PleaseWait/PleaseWait";
import styles from "./CheckCrossButton.module.css";

export default function CheckCrossButton({
  onCheck = () => {},
  onCross = null,
  loading = false,
  icon1 = <FaCheck color="green" />,
  icon2 = <RxCross2 color="red" />,
}) {
  const navigate = useNavigate();

  return (
    <div className={styles?.container}>
      <div className={styles.actions}>
        {loading ? (
          <PleaseWait />
        ) : (
          <>
            <button
              onClick={onCheck}
              className={[styles.approveBtn, styles.actionBtn].join(" ")}
            >
              {icon1}
            </button>
            <button
              className={[styles.rejectBtn, styles.actionBtn].join(" ")}
              onClick={() => (onCross ? onCross() : navigate(-1))}
            >
              {icon2}
            </button>
          </>
        )}
      </div>
    </div>
  );
}

import { Delete } from "@mui/icons-material";
import { RiEditLine } from "react-icons/ri";
import classes from "./WealthHealthCard.module.css";

const WealthHealthCard = ({ 
  data, 
  handleDelete, 
  handleEdit, 
  showIcon = true, 
  hasSubTitle = false, 
}) => {
  const renderMedia = () => {
    if (data?.videoUrl) {
      return (
        <div className={classes.mainVideoContainer}>
          <iframe
            width="100%"
            height="315"
            src={
              data?.videoUrl.includes('youtu.be')
                ? data?.videoUrl.replace('youtu.be', 'youtube.com/embed').split('?')[0]
                : data?.videoUrl.replace('watch?v=', 'embed/')
            }
            title="Video"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    } else if (data?.image) {
      return (
        <div className={classes.mainImageContainer}>
          <img src={data?.image} alt="Main Image" />
          {showIcon && (
            <div className={classes.iconContainer}>
              <img src={data?.icon} alt="Icon" />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <div className={classes.mainContainer}>
      {renderMedia()}
      <div className="d-flex justify-content-between">
        <h6 >{data?.title}</h6>
        <div className={classes.actionsDiv}>
          <RiEditLine
            size={28}
            className="curser-pointer"
            onClick={handleEdit}
          />
          <Delete size={28} className="curser-pointer" onClick={handleDelete} />
        </div>
      </div>

      <p>{hasSubTitle ? data?.subtitle : data?.description}</p>
    </div>
  );
};

export default WealthHealthCard;

import { Fragment } from "react";
import { mergeClass } from "../../../utils/helper";
import MessageDateSeparator from "../../atoms/MessageDateSeparator/MessageDateSeparator";
import classes from "./ChattingMessages.module.css";
import moment from "moment-timezone";

export default function ChattingMessages({
  data = null,
  index = -1,
  messages = [],
  userId = "",
  professionalName = "",
}) {
  const userTimeZone = moment.tz.guess();

  return (
    <Fragment>
      <MessageDateSeparator index={index} message={data} messages={messages} />

      <div className={mergeClass(classes.subDivOne, index !== 0 && "mt-3")}>
        <span
          className={mergeClass(
            classes.font,
            data?.userId == userId ? classes.sentBy : classes.receivedBy
          )}
        >
          {(data?.userId == userId ? professionalName : data?.userName) ||
            (data?.userName == "Safer Admin" ? "You" : data?.userName)}
        </span>
        <div
          className={mergeClass(
            data?.userId == userId && classes.sentMsgsMainDiv
          )}
        >
          <div className={classes.sentMsgsDiv}>
            <span
              className={mergeClass(
                classes.font,
                data?.userId == userId
                  ? classes.sentMessage
                  : classes.receivedMessage
              )}
            >
              {data?.message}
            </span>
          </div>
        </div>
        <span
          className={mergeClass(
            classes.font,
            data?.userId == userId ? classes.time : classes.receivedTime
          )}
        >
          <p>
            {moment.utc(data?.createdOn).tz(userTimeZone).format("hh:mm a")}
          </p>
        </span>
      </div>
    </Fragment>
  );
}

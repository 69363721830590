import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get, Put } from "../../interceptor/axiosInterceptors";
import Content from "../../component/atoms/Content/Content";
import Header from "../../component/Header/Header";
import LoadMore from "../../component/molecules/LoadMore/LoadMore";
import ProfileNameEmail from "../../component/molecules/ProfileNameEmail/ProfileNameEmail";
import RenderToast from "../../component/molecules/RenderToast";
import SubHeader from "../../component/organisms/SubHeader/SubHeader";
import { baseURL } from "../../resources/utils/helper";
import { RECORDS_LIMIT } from "../../data/constants";
import useDebounce from "../../resources/hooks/useDebounce";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { apiHeader } from "../../utils/helper";
import classes from "./Advisers.module.css";
import AddEditAdviserCategoryModal from "../../modals/AddEditAdviserCategoryModal/AddEditAdviserCategoryModal";
import AdviserCard from "../../component/molecules/AdviserCard/AdviserCard";
import { Delete } from "@mui/icons-material";
import { RiEditLine } from "react-icons/ri";
import { Button } from "../../component/atoms/Button/Button";
import { FaPlus } from "react-icons/fa6";

export default function Advisers() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  console.log("🚀 ~ Advisers ~ selectedCategory:", selectedCategory)
  const [loading, setLoading] = useState(""); // loading, loadMore, deleteLoading
  const [modal, setModal] = useState(false);
  const [modalCategory, setModalCategory] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 500);

  const getData = async ({
    PageNumber = page,
    loadMore = false,
    search = searchDebounce,
  }) => {
    const query = {
      PageSize: RECORDS_LIMIT,
      Text: search,
      PageNumber,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`AdvisorPages?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data?.data;
      setTotalPages(response?.data?.totalPages);
      setData((prev) => {
        const _data = data || [];
        if (loadMore) return [...prev, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

  const handleDeleteItem = async () => {
    setLoading("deleteLoading");
    const response = await Put(
      baseURL(
        selectedCategory
          ? `AdvisorPage/delete-all-by-category?id=${selectedCategory}`
          : `AdvisorPage/delete-advisor?id=${selectedItem}`
      ),
      {},
      apiHeader(accessToken)
    );
    if (response) {
      RenderToast({
        type: "success",
        message: `Successfully Deleted`,
      });
      await getData({
        PageNumber: page,
        search: searchDebounce,
        loadMore: page > 1,
      });
    }
    setModal(false);
    setLoading("");
  };

  useEffect(() => {
    getData({
      PageNumber: 1,
    });
  }, [searchDebounce]);

  return (
    <div className={classes.mainDiv}>
      <Header />
      <ProfileNameEmail
        onClick={() => setModalCategory(true)}
        buttonLabel="Create Adviser Category"
        showBtn
      />
      <Container className="globalContainer">
        <Row className="mt-4">
          <Col>
            <SubHeader
              title={"Advisers"}
              showSearch
              setSearch={setSearch}
              search={search}
            />
            <div className={classes.reqCardClass}>
              <Content
                {...{
                  data,
                  noDataText: "No Data Found",
                  loading: loading === "loading",
                }}
              >
                <Row className="pb-5">
                  {data.map((item, index) => {
                    return (
                      <>
                        <div className={classes.category}>
                          <div className={classes.title}>
                            <h3>{item.category}</h3>
                            <p>{item.catDesc}</p>
                          </div>
                          <div className={classes.actionsDiv}>
                            <RiEditLine
                              size={28}
                              className="curser-pointer"
                              onClick={() => {
                                setModalCategory(true);
                                setSelectedCategory(item);
                              }}
                            />
                            <Delete
                              size={28}
                              className="curser-pointer"
                              onClick={() => {
                                setModal(true);
                                setSelectedCategory(item.id);
                              }}
                            />
                          </div>
                        </div>
                        <div className={classes.addAdviserButton}>
                          <Button
                            onClick={() =>
                              navigate(
                                `/add-edit-adviser?categoryId=${item.id}`
                              )
                            }
                            rightIcon={<FaPlus size={20} />}
                            variant="secondary"
                            className={classes.addBtn}
                          />
                        </div>
                        <Row className={`${classes.row} gy-4 mt-4`}>
                          {item?.advisors.length ? (
                            item?.advisors?.map((adviser, index) => (
                              <Col md={4} lg={3} key={index}>
                                <AdviserCard
                                  item={adviser}
                                  handleEdit={() => {
                                    navigate(`/add-edit-adviser/${adviser?.id}?categoryId=${item.id}`);
                                  }}
                                  handleDelete={() => {
                                    setModal(true);
                                    setSelectedItem(adviser?.id);
                                  }}
                                />
                              </Col>
                            ))
                          ) : (
                            <p className={classes.noData}>
                              No Professionals Found...
                            </p>
                          )}
                        </Row>
                      </>
                    );
                  })}
                </Row>
                <LoadMore
                  {...{
                    page,
                    totalPages,
                    loading: loading === "loadMore",
                    setPage: () => {
                      const _page = page + 1;
                      setPage(_page);
                      getData({ loadMore: true, PageNumber: _page });
                    },
                  }}
                />
              </Content>
            </div>
          </Col>
        </Row>

        {modal && (
          <AreYouSureModal
            show={modal}
            setShow={setModal}
            subTitle={`Are you sure you want to Delete ${
              selectedItem ? "this adviser" : "this Category"
            } ?`}
            onClick={handleDeleteItem}
            loading={loading === "deleteLoading"}
          />
        )}
        {modalCategory && (
          <AddEditAdviserCategoryModal
            show={modalCategory}
            setShow={setModalCategory}
            slug={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            getData={getData}
          />
        )}
      </Container>
    </div>
  );
}

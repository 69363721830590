import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { AiOutlinePercentage } from "react-icons/ai";
import { MdOutlineAttachment } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get } from "../../../interceptor/axiosInterceptors";
import { baseURL } from "../../../resources/utils/helper";
import useDebounce from "../../../resources/hooks/useDebounce";
import { Referral } from "../../../data/app-data";
import { RECORDS_LIMIT } from "../../../data/constants";
import { Loader } from "../../molecules/Loader";
import LoadMore from "../../molecules/LoadMore/LoadMore";
import NoData from "../../atoms/NoData/NoData";
import ReferralCard from "../../molecules/ReferralCard";
import StatsCardsTwo from "../../molecules/StatsCardTwo";
import SubHeader from "../SubHeader/SubHeader";
import classes from "./ReferralsComponent.module.css";

export default function ReferralsComponent({ slug, setTab }) {
  const navigate = useNavigate();
  const [tabs, setTabs] = useState(Referral[0].value);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [userPage, setUserPage] = useState(1);
  const [userTotalPages, setUserTotalPages] = useState(0);
  const [professionalPage, setProfessionalPage] = useState(1);
  const [professionalTotalPages, setProfessionalTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [professionalData, setProfessionalData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const searchDebounce = useDebounce(search, 500);
  const { accessToken } = useSelector((state) => state?.authReducer);

  const getData = async () => {
    const url = baseURL(`User/referral-details?userId=${slug}`);
    setLoading(true);
    const response = await Get(url, accessToken, false);
    setLoading(false);
    setData(response?.data?.data);
  };

  const getProfessional = async (
    loadMore = false,
    PageNumber = 1,
    search = ""
  ) => {
    const query = {
      PageSize: RECORDS_LIMIT,
      PageNumber,
      search,
    };
    let queryParams = new URLSearchParams(query);
    const url = baseURL(
      `User/Professional-referrals?userId=${slug}&${queryParams}`
    );
    setLoading(loadMore ? "loadMore" : "loading");
    const response = await Get(url, accessToken, false);

    if (response) {
      const data = response?.data;
      setProfessionalTotalPages(data?.totalPages);
      setProfessionalData((prev) => {
        const newData = data?.data || [];
        if (loadMore) return [...prev, ...newData];
        return newData;
      });
    }
    setLoading("");
  };

  const getClient = async (loadMore = false, PageNumber = 1, search = "") => {
    const query = {
      PageSize: RECORDS_LIMIT,
      PageNumber,
      search,
    };
    let queryParams = new URLSearchParams(query);
    const url = baseURL(`User/client-referrals?userId=${slug}&${queryParams}`);
    setLoading(loadMore ? "loadMore" : "loading");
    const response = await Get(url, accessToken, false);

    if (response) {
      const data = response?.data;
      setUserTotalPages(data?.totalPages);
      setClientData((prev) => {
        const newData = data?.data || [];
        if (loadMore) return [...prev, ...newData];
        return newData;
      });
    }
    setLoading("");
  };

  useEffect(() => {
    getData();
    if (tabs === "user") {
      getClient(false, 1, searchDebounce);
    }
    if (tabs === "professionals") {
      getProfessional(false, 1, searchDebounce);
    }
  }, [tabs, searchDebounce, slug]);

  const renderContent = () => {
    if (loading && loading !== "loadMore") {
      return <Loader />;
    }

    if (tabs === "user") {
      return (
        <>
          {clientData && clientData.length > 0 ? (
            clientData.map((client, index) => (
              <ReferralCard
                underLineClass={classes.underLineClass}
                key={index}
                data={client}
                role="Client"
              />
            ))
          ) : (
            <NoData message="No client referrals found." />
          )}

          <LoadMore
            page={userPage}
            totalPages={userTotalPages}
            loading={loading === "loadMore"}
            setPage={() => {
              const _page = userPage + 1;
              setUserPage(_page);
              getClient(true, _page, searchDebounce);
            }}
          />
        </>
      );
    }

    if (tabs === "professionals") {
      return (
        <>
          {professionalData && professionalData.length > 0 ? (
            professionalData.map((professional, index) => (
              <ReferralCard
                key={index}
                data={professional}
                role="Professional"
                onClick={() => {
                  setTab("details");
                  navigate(`/professionals/${professional?.userId}`);
                }}
              />
            ))
          ) : (
            <NoData message="No professional referrals found." />
          )}

          <LoadMore
            page={professionalPage}
            totalPages={professionalTotalPages}
            loading={loading === "loadMore"}
            setPage={() => {
              const _page = professionalPage + 1;
              setProfessionalPage(_page);
              getProfessional(true, _page, searchDebounce);
            }}
          />
        </>
      );
    }

    return <NoData />;
  };

  return (
    <>
      <Row>
        <div className={classes.mainDiv}>
          <div>
            {" "}
            <StatsCardsTwo
              icon={<MdOutlineAttachment size={22} color="white" />}
              title={"Total Referrals"}
              data={data?.totalReferrals}
            />
          </div>
          <div>
            {" "}
            <StatsCardsTwo
              icon={<AiOutlinePercentage size={22} color="white" />}
              title={"Total Discounts"}
              data={data?.totalDiscount}
              Percent={"%"}
            />
          </div>
        </div>
        <SubHeader
          tabs={Referral}
          tab={tabs}
          setTab={setTabs}
          showTabs={true}
          showSearch={true}
          search={search}
          setSearch={setSearch}
        />
      </Row>
      <Row>{renderContent()}</Row>
    </>
  );
}

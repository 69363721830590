import moment from "moment";
import React from "react";
import classes from "./MessageDateSeparator.module.css";

const MessageDateSeparator = ({ index, message, messages }) => {
  return (
    <>
      {index == 0 ? (
        <div className={classes.msgDate}>
          <p>{moment(message?.createdOn).format("DD MMM YYYY")}</p>
        </div>
      ) : (
        moment(messages[index - 1]?.createdOn).format("DD MMM YYYY") !==
          moment(message?.createdOn).format("DD MMM YYYY") && (
          <div className={classes.msgDate}>
            <p>{moment(message?.createdOn).format("DD MMM YYYY")}</p>
          </div>
        )
      )}
    </>
  );
};

export default MessageDateSeparator;

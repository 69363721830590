import React from "react";
import { MdOutlineAccountCircle } from "react-icons/md";
import classes from "./UserNotificationCard.module.css";

export default function UserNotificationCard({ name, email, message }) {
  return (
    <div
      className={`${classes.notification_item} d-flex justify-content-between`}
    >
      <div className="d-flex flex-column gap-1">
        <div className="d-flex align-items-center">
          <MdOutlineAccountCircle
            size={50}
            color="#36696B"
            style={{ marginRight: "15px" }}
          />
          <div className={classes.profile_details}>
            <p className={classes.profile_name}>{name}</p>
            <p className={classes.profile_email}>{email}</p>
          </div>
        </div>
        <p className={classes.message}>{message}</p>
      </div>
    </div>
  );
}

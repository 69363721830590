import * as signalR from "@microsoft/signalr";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import React, { Suspense, lazy, useEffect, useRef } from "react";
import "react-clock/dist/Clock.css";
import "react-modern-drawer/dist/index.css";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { config } from "../src/config";
import "react-toastify/dist/ReactToastify.min.css";
import { Get } from "./interceptor/axiosInterceptors";
import { Loader } from "./component/molecules/Loader";
import BeforeLoginRoute from "./utils/unauthorizeRouteWrapper ";
import ProtectedRouter from "./utils/authorizeRouteWrapper";
import "./assets/Styles/style.css";
import AddEditProtectionSolution from "./pages/AddEditProtectionSolution";
import CreateSubAdmin from "./pages/CreateSubAdmin/CreateSubAdmin";
import Curds from "./pages/Curds";
import HomePageCMS from "./pages/HomePageCMS";
import NotificationsView from "./pages/NotificationsView/NotificationsView";
import ProfessionalsDetail from "./pages/ProfessionalsDetail/ProfessionalsDetail";
import ProtectionSolutionPage from "./pages/ProtectionSolutionPage";
import AddQuestion from "./pages/Questionnaire/AddQuestion/AddQuestion";
import Questionnaire from "./pages/Questionnaire/Questionnaire";
import SingleChatPage from "./pages/SingleChatPage/SingleChatPage";
import SubAdmin from "./pages/SubAdmin/SubAdmin";
import SubscriptionView from "./pages/Subscriptions/SubscriptionView";
import SupportPage from "./pages/SupportPage/SupportPage";
import UserDetail from "./pages/UserDetail/UserDetail";
import WealthAndHealthProtection from "./pages/WealthAndHealthProtection";
import { updateUser } from "./store/auth/authSlice";
import { setNotificationCount } from "./store/common/commonSlice";
import { baseURL } from "./resources/utils/helper";
import News from "./pages/News/News";
import AddEditNews from "./pages/AddEditNews/AddEditNews";
import Markets from "./pages/Markets/Markets";
import AddEditMarket from "./pages/AddEditMarket/AddEditMarket";
import Advisers from "./pages/Advisers/Advisers";
import AddEditAdviser from "./pages/AddEditAdviser/AddEditAdviser";
import ContactsView from "./pages/ContactsView/ContactsView";
import ProfileSettings from "./pages/ProfileSettings/ProfileSettings";

const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Wallet = lazy(() => import("./pages/Wallet/Wallet"));
const Request = lazy(() => import("./pages/Request/Request"));
const Category = lazy(() => import("./pages/Category/Category"));
const AddEditHealthProtection = lazy(() =>
  import("./pages/AddEditHealthProtection")
);
const Users = lazy(() => import("./pages/Users/Users"));
const JobDetails = lazy(() => import("./pages/JobDetails/JobDetails"));
const AddCategory = lazy(() =>
  import("./pages/Category/AddCategory/AddCategory")
);
const NotFound = lazy(() => import("./pages/NotFound"));
const Professional = lazy(() => import("./pages/Professionals/Professionals"));
const Rewards = lazy(() => import("./pages/Rewards/Rewards"));
const ResetPassword = lazy(() => import("./pages/ResetPasswordPage"));
const SessionDetail = lazy(() => import("./pages/SessisonDetailPage"));
const ServicesDetail = lazy(() => import("./pages/ServicesDetail"));
const ServicesCommissionPage = lazy(() =>
  import("./pages/ServicesDetail/EditCommission")
);
const CreateReward = lazy(() => import("./pages/CreateReward/CreateReward"));
const ManageZoom = lazy(() => import("./pages/ManageZoom/ManageZoom"));

function App() {
  const { user, isLogin, accessToken, notificationCount } = useSelector(
    (state) => state?.authReducer
  );
  const dispatch = useDispatch();
  const connection = useRef(null);

  const getMe = async () => {
    const url = baseURL("User");
    const response = await Get(url, accessToken);

    if (response) {
      const user = response?.data?.data;
      dispatch(updateUser(user));
    }
  };

  useEffect(() => {
    if (isLogin) {
      getMe();
    }
  }, [isLogin]);

  useEffect(() => {
    if (!isLogin) return;

    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(`${config?.apiBaseUrl}/notificationHub`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .build();

    connection.current
      .start()
      .then(() => {
        console.log("SignalR connected");

        connection.current.on(`notification_${user?.id}`, (notification) => {
          dispatch(setNotificationCount(notificationCount + 1));
        });
      })
      .catch((err) => console.error("Connection error:", err.toString()));

    // Cleanup connection and listener on component unmount or `isLoggedIn` change
    return () => {
      connection.current
        .stop()
        .then(() => console.log("SignalR disconnected"))
        .catch((err) => console.error("Disconnect error:", err.toString()));
    };
  }, [isLogin]);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<Loader className={"mvh-100"} />}>
          <Routes>
            <Route
              path="/"
              exact
              element={<BeforeLoginRoute element={<Login />} />}
            />

            <Route
              path="/dashboard"
              exact
              element={<ProtectedRouter element={<Dashboard />} />}
            />
            <Route
              exact
              path="/services/:id"
              element={<ProtectedRouter element={<ServicesDetail />} />}
            />
            <Route
              exact
              path="/services/detail/edit/commission/:id"
              element={<ProtectedRouter element={<ServicesCommissionPage />} />}
            />
            <Route
              path="/professionals"
              exact
              element={<ProtectedRouter element={<Professional />} />}
            />
            <Route
              path="/auth/reset-password"
              exact
              element={<ResetPassword />}
            />
            <Route
              path="/professionals/:slug"
              exact
              element={<ProtectedRouter element={<ProfessionalsDetail />} />}
            />
            <Route
              path="/notifications"
              exact
              element={<ProtectedRouter element={<NotificationsView />} />}
            />
            <Route
              path="/sub-admins"
              exact
              element={<ProtectedRouter element={<SubAdmin />} />}
            />
            <Route
              path="/session/detail/:id"
              element={<ProtectedRouter element={<SessionDetail />} />}
            />
            <Route
              path="/create-sub-admin"
              exact
              element={<ProtectedRouter element={<CreateSubAdmin />} />}
            />
            <Route
              path="/users/:slug"
              exact
              element={<ProtectedRouter element={<UserDetail />} />}
            />
            <Route
              path="/wallet"
              exact
              element={<ProtectedRouter element={<Wallet />} />}
            />
            <Route
              path="/requests"
              exact
              element={<ProtectedRouter element={<Request />} />}
            />
            <Route
              path="/cruds"
              exact
              element={<ProtectedRouter element={<Curds />} />}
            />
             <Route
              path="/settings"
              exact
              element={<ProtectedRouter element={<ProfileSettings/>} />}
            />
            <Route
              path="/contacts"
              exact
              element={<ProtectedRouter element={<ContactsView/> } />}
            />
            <Route
              path="/professional-request-chat"
              exact
              element={<ProtectedRouter element={<SingleChatPage />} />}
            />
            <Route
              path="/support-chat"
              exact
              element={<ProtectedRouter element={<SingleChatPage />} />}
            />
            <Route
              path="/wealth-health-protection"
              exact
              element={
                <ProtectedRouter element={<WealthAndHealthProtection />} />
              }
            />
             <Route
              path="/news"
              exact
              element={
                <ProtectedRouter element={<News />} />
              }
            />
            <Route
              path="/markets"
              exact
              element={
                <ProtectedRouter element={<Markets/> } />
              }
            />
            <Route
              path="/advisers"
              exact
              element={
                <ProtectedRouter element={<Advisers/> } />
              }
            />
            <Route
              path="/protection-solutions"
              exact
              element={<ProtectedRouter element={<ProtectionSolutionPage />} />}
            />
            <Route
              path="/add-edit-protection-solutions"
              exact
              element={
                <ProtectedRouter element={<AddEditProtectionSolution />} />
              }
            />
            <Route
              path="/add-edit-protection-solutions/:slug"
              exact
              element={
                <ProtectedRouter element={<AddEditProtectionSolution />} />
              }
            />
            <Route
              path="/add-edit-health-protection/:slug"
              exact
              element={
                <ProtectedRouter element={<AddEditHealthProtection />} />
              }
            />
            <Route
              path="/add-edit-health-protection"
              exact
              element={
                <ProtectedRouter element={<AddEditHealthProtection />} />
              }
            />
            <Route
              path="/add-edit-news/:slug"
              exact
              element={
                <ProtectedRouter element={<AddEditNews/>} />
              }
            />
            <Route
              path="/add-edit-news"
              exact
              element={
                <ProtectedRouter element={<AddEditNews/> } />
              }
            />
             <Route
              path="/add-edit-market/:slug"
              exact
              element={
                <ProtectedRouter element={<AddEditMarket/> } />
              }
            />
            <Route
              path="/add-edit-market"
              exact
              element={
                <ProtectedRouter element={<AddEditMarket/> } />
              }
            />
             <Route
              path="/add-edit-adviser/:slug"
              exact
              element={
                <ProtectedRouter element={<AddEditAdviser/> } />
              }
            />
            <Route
              path="/add-edit-adviser"
              exact
              element={
                <ProtectedRouter element={<AddEditAdviser/> } />
              }
            />
            <Route
              path="/cms/landing-page"
              exact
              element={<ProtectedRouter element={<HomePageCMS />} />}
            />
            <Route
              path="/category"
              exact
              element={<ProtectedRouter element={<Category />} />}
            />
            <Route
              path="/users"
              exact
              element={<ProtectedRouter element={<Users />} />}
            />
            <Route
              path="/jobs/:slug"
              exact
              element={<ProtectedRouter element={<JobDetails />} />}
            />
            <Route
              path="/addcategory"
              exact
              element={<ProtectedRouter element={<AddCategory />} />}
            />
            <Route
              path="/rewards"
              exact
              element={<ProtectedRouter element={<Rewards />} />}
            />
            <Route
              path="/rewards/create-reward"
              exact
              element={<ProtectedRouter element={<CreateReward />} />}
            />
            <Route
              path="/questionnaire"
              exact
              element={<ProtectedRouter element={<Questionnaire />} />}
            />
            <Route
              path="/addquestion"
              exact
              element={<ProtectedRouter element={<AddQuestion />} />}
            />
            <Route
              path="/subscriptions"
              exact
              element={<ProtectedRouter element={<SubscriptionView />} />}
            />
            <Route
              path="/support"
              exact
              element={<ProtectedRouter element={<SupportPage />} />}
            />
            <Route
              path="/manage-zoom"
              exact
              element={<ProtectedRouter element={<ManageZoom />} />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;

console.log("🚀🚀🚀🚀🚀🚀❌❌❌❌❌❌❌❌❌🚀🚀🚀🚀🚀🚀");

import React from "react";
import { RELATED_SOLUTION } from "../../../data/app-data";
import Styles from "./RelatedSolution.module.css";

const heading = ["industries", "markets", "practices"];

const RelatedSolution = ({ data }) => {
  return (
    <>
      <div className={Styles.relatedSolution}>
        {RELATED_SOLUTION.map((solution, i) => (
          <div className={Styles.tableSec} key={solution.id}>
            <div className={Styles.proDetailTable}>
              <div className={Styles.proTableHead}>
                <p>
                  <strong>{heading[i].toUpperCase()}</strong>
                </p>
              </div>
              <div className={Styles.proTableBody}>
                {!data?.[heading[i]]?.length && "N/A"}
                {data?.[heading[i]]?.map((item) => {
                  return (
                    <p>
                      {item?.industryName ||
                        item?.practiceName ||
                        item?.marketName}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default RelatedSolution;

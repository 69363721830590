import { Col, Row } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdOutlineRequestPage } from "react-icons/md";
import { getFormattedPrice, mergeClass } from "../../../utils/helper";
import { Button } from "../../atoms/Button/Button";
import classes from "./ServiceCard.module.css";

const ServiceCard = ({
  services,
  showRequests = true,
  onClick,
  buttonLabel = "View Details",
}) => {
  return (
    <Row className={mergeClass(classes.service_card)}>
      <Col lg={8}>
        <div>
          <p
            className={mergeClass("maxLine1", classes.title)}
            title={services?.serviceCategoryName}
          >
            {services?.serviceCategoryName}
          </p>
          <h3 className={mergeClass("maxLine1")} title={services?.title}>
            {services?.title}
          </h3>
        </div>
        <div className={classes.pricingDiv}>
          <p>Pricing</p>
          <h5>{getFormattedPrice(services?.amount)}</h5>
        </div>
        {showRequests && (
          <p className="d-flex align-items-center gap-2 mt-2">
            <MdOutlineRequestPage size={20} />
            <b> {services?.totalPendingRequests}</b>
            <span>
              <b>Requests</b>
            </span>
          </p>
        )}
      </Col>

      <Col lg={4} className={mergeClass("center")}>
        <Button
          className={classes.btn}
          label={buttonLabel}
          onClick={onClick}
          rightIcon={<FaArrowRightLong size={18} />}
        />
      </Col>
    </Row>
  );
};

export default ServiceCard;

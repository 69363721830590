import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDepartments: [],
  allCategories: [],
  noti_counter: 0,
  chat_counter: 0,
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setAllDepartments(state, action) {
      state.allDepartments = action.payload;
    },
    setAllCategories(state, action) {
      state.allCategories = action.payload;
    },
    saveNotiData(state, action) {
      state.noti_counter += action?.payload || 1;
    },
    setNotificationCount(state, action) {
      state.notificationCount = action.payload;
    },
    clearNotiData(state, action) {
      state.noti_counter = 0;
      state.noti_data = [];
    },
    saveChatCounter(state, action) {
      state.chat_counter += 1;
    },
    clearChatCounter(state, action) {
      state.chat_counter = 0;
    },
  },
});

export const {
  setAllDepartments,
  setAllCategories,
  saveNotiData,
  saveChatCounter,
  clearChatCounter,
  clearNotiData,
  setNotificationCount
} = commonSlice.actions;

export default commonSlice.reducer;

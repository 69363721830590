import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineEdit } from "react-icons/ai";
import { RiDeleteBin7Fill } from "react-icons/ri";
import classes from "./QuestionCard.module.css";

export default function QuestionCard({
  data,
  onEdit = () => {},
  onDelete = () => {},
}) {
  return (
    <div className={classes.mainBox}>
      <Row>
        <Col md={11}>
          <span className={classes?.question}>{data?.questionText}</span>
        </Col>
        <Col md={1} className="d-flex gap-2">
          <AiOutlineEdit onClick={onEdit} fontSize={22} />
          <RiDeleteBin7Fill onClick={onDelete} fontSize={22} />
        </Col>
        <Col md={12} className="d-flex align-items-center gap-3 mt-3">
          {data?.option.map((item, index) => (
            <span key={index} className={classes?.optionName}>
              {item?.optionName}
            </span>
          ))}
        </Col>
      </Row>
    </div>
  );
}

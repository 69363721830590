"use client";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { PiPercent } from "react-icons/pi";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import { Post } from "../../../interceptor/axiosInterceptors";
import BackButton from "../../../component/molecules/BackButton/BackButton";
import Header from "../../../component/Header/Header";
import { Input } from "../../../component/atoms/Input/Input";
import { Loader } from "../../../component/molecules/Loader";
import RenderToast from "../../../component/molecules/RenderToast";
import SubHeader from "../../../component/organisms/SubHeader/SubHeader";
import { baseURL } from "../../../resources/utils/helper";
import { apiHeader } from "../../../utils/helper";
import classes from "./AddQuestion.module.css";

export default function AddQuestion() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    question: "",
    option: "",
  });

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.name || !formData.number) {
      return RenderToast({ type: "error", message: "Input all fields" });
    }
    if (formData.number < 0 || formData.number > 100) {
      return RenderToast({
        type: "error",
        message: "Commission has to be in range of 0 to 100",
      });
    }
    if (!validator.isNumeric) {
      return RenderToast({
        type: "error",
        message: "Input Commisions in number",
      });
    }
    setLoading(true);
    const res = await Post(
      baseURL(`ServiceCategory`),
      {
        name: formData.name,
        commission: parseInt(formData.number),
      },
      apiHeader(accessToken)
    );
    if (res) {
      RenderToast({ type: "success", message: "Successfully Updated" });
      setFormData("");
    }
    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <BackButton title={"Back"} />
      <Container>
        <Row>
          <Col>
            <div className={classes.selectSlot}>
              <div className={classes.subSec}>
                <SubHeader title="Add New Category" showSearch={false} />
              </div>

              <div className={classes.selectSlotCards}>
                <div className={classes.formContainer}>
                  <div className={`${classes.form} ${classes.formWidth}`}>
                    <Input
                      type="text"
                      placeholder="Name"
                      className={classes.input}
                      value={formData.name}
                      setter={(value) => handleChange("name", value)}
                    />

                    <Input
                      type="number"
                      placeholder="Commission"
                      className={classes.input}
                      rightIcon={<PiPercent />}
                      value={formData.number}
                      setter={(value) => handleChange("number", value)}
                    />

                    <div className={classes.actions}>
                      <button
                        onClick={handleSubmit}
                        className={[classes.approveBtn, classes.actionBtn].join(
                          " "
                        )}
                      >
                        <FaCheck color="green" />
                      </button>
                      <button
                        type="button"
                        className={[classes.rejectBtn, classes.actionBtn].join(
                          " "
                        )}
                        onClick={() => navigate(-1)}
                      >
                        <RxCross2 color="red" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

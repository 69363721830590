import { ClickAwayListener } from "@mui/material";
import { MdOutlineCancel, MdOutlineMail } from "react-icons/md";
import classes from "./ComboBox.module.css";
import { Input } from "../../atoms/Input/Input";
import { mediaUrl } from "../../../resources/utils/helper";
import { useNavigate } from 'react-router-dom';

export default function ComboBox({
  data = [],
  email = "",
  setEmail = () => {},
  handleSubmit = () => {},
  disabled = false,
  isCollaborationView = false,
}) {
  const navigate = useNavigate();

  return (
    <ClickAwayListener
      onClickAway={() => {
        setEmail("");
      }}
    >
      <div className={classes?.comboBox}>
        <Input
          disabled={disabled}
          value={email}
          setter={setEmail}
          placeholder="Search by name or email"
          leftIcon={
            <MdOutlineMail
              size={"25px"}
              color="gray"
              style={{ marginBottom: "3px" }}
            />
          }
          {...(email?.trim() && {
            rightIcon: (
              <MdOutlineCancel
                size={"22px"}
                color="gray"
                onClick={() => setEmail("")}
              />
            ),
          })}
          parentCustomStyle={{ width: "100%" }}
          className={classes.inputBox}
        />

        {!!data?.length && (
          <ul className={classes.options}>
            {data?.map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  handleSubmit(
                    isCollaborationView
                      ? { id: option?.id, email: option?.email }
                      : option?.email
                  );
                  setEmail("");
                }}
                className={classes.option}
              >
                <div className="d-flex align-items-center gap-2">
                  <div className={classes.profile}>
                    <img
                      src={
                        mediaUrl(option?.profileImagePath) ||
                        "/images/user-avatar.png"
                      }
                      alt=""
                      // width={150}
                      // height={150}
                    />
                  </div>
                  <div className={classes?.info}>
                    <span>
                      {option?.firstName} {option?.lastName}
                    </span>
                  </div>
                </div>
                  <div className={classes.view} onClick={(e)=>{
                    e.stopPropagation();
                    navigate(`/professionals/${option?.id}`);
                  }}>
                    <span>View</span>
                  </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </ClickAwayListener>
  );
}

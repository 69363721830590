import React, { useState } from "react";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./UpdatePassword.module.css";
import { Button } from "../../component/atoms/Button/Button";
import { Input } from "../../component/atoms/Input/Input";
import { apiHeader, validatePassword } from "../../utils/helper";
import RenderToast from "../../component/molecules/RenderToast";
import { baseURL } from "../../resources/utils/helper";
import { Post } from "../../interceptor/axiosInterceptors";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";

const inputStyle = {};

const UpdatePasswordModal = ({ show, setShow }) => {
   const { accessToken } = useSelector((state) => state?.authReducer);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = async () => {
    const params = {
      passwordCurrent: currentPassword,
      password: newPassword,
      passwordConfirm: confirmPassword,
    };

    for (let key in params) {
      if (!params[key])
        return RenderToast({
          type: "warn",
          message: `Fields can not be empty.`,
        });

      if (key === "password") {
        const errors = validatePassword(params[key]);

        if (errors.length > 0) {
          return RenderToast({ message: errors[0], type: "warn" });
        }
      }

      if (newPassword !== confirmPassword)
        return RenderToast({
          type: "warn",
          message: `Password and confirm password do not match.`,
        });
    }

    const url = baseURL(`User/changepassword`);
    setIsLoading(true);

    const response = await Post(
      url,
      { oldPassword: currentPassword, newPassword },
      apiHeader(accessToken)
    );

    setIsLoading(false);

    if (response) {
      RenderToast({
        type: "success",
        message: `Your password has been changed`,
      });
    }

    setShow(false);
  };

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={"Change Password"}
      showCloseIcon
    >
      <Row className={classes.row}>
        <Col md={12}>
          <Input
            setter={setCurrentPassword}
            value={currentPassword}
            placeholder={"Enter Current Password"}
            label={"Current Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setter={setNewPassword}
            value={newPassword}
            placeholder={"Enter New password"}
            label={"New Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>
        <Col md={12}>
          <Input
            setter={setConfirmPassword}
            value={confirmPassword}
            placeholder={"Enter Confirm password"}
            label={"Confirm Password"}
            type={"password"}
            customStyle={inputStyle}
          />
        </Col>

        <Col md={12} className={classes.signUpBtnWrap}>
          <Button onClick={handleUpdate} variant="secondary">
            {isLoading ? "Updating..." : "Update Password"}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
};

export default UpdatePasswordModal;

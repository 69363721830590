import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as React from "react";
import classes from "./DateInput.module.css";

export default function DateInput({
  value,
  setValue,
  placeholder,
  customClass,
  label,
  maxDate,
  minDate,
  ...props
}) {
  return (
    <>
      <style>{`
         .${classes.input} input:placeholder {
                 opacity: 1 !important;
                  }
          .MuiOutlinedInput-notchedOutline {
            border: none;
          }`}</style>
      {label && (
        <label
          htmlFor={`input${label}`}
          className={`${[classes.labelText].join(" ")}`}
        >
          {label}
        </label>
      )}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={value}
          maxDate={maxDate}
          minDate={minDate}
          className={[classes.input, customClass].join(" ")}
          onChange={(newValue) => setValue(newValue)}
          renderInput={(params) => (
            <TextField
              InputLabelProps={{ shrink: false, placeholder: placeholder }}
              onKeyDown={(e) => e.preventDefault()}
              {...params}
            />
          )}
          {...props}
        />
      </LocalizationProvider>
    </>
  );
}

import classes from "./Status.module.css";

export default function Statuses({ status }) {
  if (["block", "decline", "inactive", "rejected", "reject"].includes(status)) {
    return <span className={`${classes.status} ${classes.red}`}>{status}</span>;
  }

  if (["approved", "active", "completed", "successful", "resolved"].includes(status)) {
    return (
      <span className={`${classes.status} ${classes.green}`}>{status}</span>
    );
  }

  if (["pending", "in progress"].includes(status)) {
    return (
      <span className={`${classes.status} ${classes.orange}`}>{status}</span>
    );
  }
}

import React, { useState } from "react";
import Header from "../../component/Header/Header";
import { Col, Container, Row } from "react-bootstrap";
import ProfileNameEmail from "../../component/molecules/ProfileNameEmail/ProfileNameEmail";
import classes from "./ProfileSettings.module.css";
import { ProfileWithEditButton } from "../../component/organisms/ProfileWithEditButton";
import { Input } from "../../component/atoms/Input/Input";
import { Button } from "../../component/atoms/Button/Button";
import UpdatePasswordModal from "../../modals/UpdatePasswordModal/UpdatePasswordModal";
import { apiHeader, createFormData } from "../../utils/helper";
import { baseURL } from "../../resources/utils/helper";
import { useSelector } from "react-redux";
import { Get, Put } from "../../interceptor/axiosInterceptors";
import RenderToast from "../../component/molecules/RenderToast";

import { useDispatch } from "react-redux";
import { updateUser } from "../../store/auth/authSlice";

export default function ProfileSettings() {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const { user } = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const [uploadImg, setUploadImg] = useState(user?.profileImagePath);
  const [loading, setLoading] = useState(""); //submitting reloading
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [show, setShow] = useState(false);

  const handleSubmit = async () => {
    const body = {
      file: uploadImg,
      firstName: firstName,
      lastName: lastName,
    };

    const formData = createFormData(body);

    setLoading("submitting");

    const response = await Put(
      baseURL(`User`),
      formData,
      apiHeader(accessToken, true)
    );
    if (response) {
        setLoading("reloading");
      const userUpdated = await Get(baseURL("User"), accessToken, false);
      if (userUpdated) {
        const user = userUpdated?.data?.data;
        dispatch(updateUser(user));
        // window.location.reload();
      }

      RenderToast({
        message: "Profile updated successfully!",
        type: "success",
      });
    }
    setLoading("");
  };

  return (
    <>
      <Header />
      <Container>
            <ProfileNameEmail/>
        <h5 className="my-4">Profile Settings</h5>
        <div className={classes.form}>
          <Row>
            <Col className="mb-4">
              <ProfileWithEditButton
                isEdit
                onClick={setUploadImg}
                setUpdateImage={setUploadImg}
                updateImage={uploadImg}
              />
            </Col>
            <Col lg={12} className={classes.formGaps}>
              <Input
                type="text"
                placeholder="First Name"
                className={classes.input}
                value={firstName}
                setter={setFirstName}
                mainContainer="mb-3"
                maxLength="255"
              />

              <Input
                type="text"
                placeholder="Last Name"
                className={classes.input}
                value={lastName}
                setter={setLastName}
                mainContainer="mb-3"
                maxLength="255"
              />

              <div className={classes.modalButtons}>
                <Button
                  onClick={() => setShow(true)}
                  variant="secondary"
                  className={classes.button}
                >
                  Change Password
                </Button>

                <Button onClick={handleSubmit} className={classes.button}>
                  {loading == "submitting" ? "Updating..." : "Update"}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        {show && <UpdatePasswordModal show setShow={setShow} />}
      </Container>
    </>
  );
}

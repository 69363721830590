import React from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import { Rating } from "react-simple-star-rating";
import { clockIcon } from "../../../constant/imagePath";
import { mergeClass, timeAgo } from "../../../utils/helper";
import classes from "./ReviewsCard.module.css";

export default function ReviewsCard({ reviews }) {
  return (
    <div className={classes.card}>
      <div className={classes.header}>
        <FaRegCircleUser
          size={"30"}
          color="var(--user-icon-color)"
          className={classes.userIcon}
        />
        <span className={mergeClass(classes.font, classes.userName)}>
          {reviews?.reviewerName || ""}
        </span>
      </div>
      <div className={classes.body}>
        <span className={mergeClass(classes.subTitle, classes.font)}>
          {reviews?.serviceCategoryName || ""}
        </span>
        <span className={mergeClass(classes.title, classes.font)}>
          {reviews?.professionalServiceName || ""}
        </span>
        <div className={classes.timeDiv}>
          <img src={clockIcon} className={classes.clockIcon} alt="clockIcon" />
          <span className={mergeClass(classes.font, classes.time)}>
            {" "}
            <span className={classes.timeText}>{timeAgo(reviews?.date)}</span>
          </span>
        </div>
        <span className={mergeClass(classes.desc, classes.font)}>
          {reviews?.comments || ""}
        </span>
        <Rating initialValue={reviews?.rating} size={20} readonly />
        <span className={mergeClass(classes.rating, classes.font)}>
          {reviews?.rating} Avg. Rating
        </span>
      </div>
    </div>
  );
}

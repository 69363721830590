export const JOBS_DETAIL_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Chats",
    value: "chats",
  },
];

export const SUPPORT_TABS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const HEADER = [
  {
    label: "Home",
    value: "/dashboard",
  },
  {
    label: "Professionals",
    value: "/professionals",
  },
  {
    label: "Rewards",
    value: "/rewards",
  },
  {
    label: "Clients",
    value: "/users",
  },
  {
    label: "Sub Admins",
    value: "/sub-admins",
  },
  {
    label: "Requests",
    value: "/requests",
  },

  {
    label: "Category",
    value: "/category",
  },
  {
    label: "Wallet",
    value: "/wallet",
  },
  {
    label: "Questionnaire",
    value: "/questionnaire",
  },
  {
    label: "Subscriptions",
    value: "/subscriptions",
  },
];

export const HEADER_SUB_ADMIN = [
  {
    label: "Home",
    value: "/dashboard",
  },
  {
    label: "Professionals",
    value: "/professionals",
  },
  {
    label: "Rewards",
    value: "/rewards",
  },
  {
    label: "Clients",
    value: "/users",
  },
  {
    label: "Requests",
    value: "/requests",
  },

  {
    label: "Category",
    value: "/category",
  },
  {
    label: "Wallet",
    value: "/wallet",
  },
  {
    label: "Questionnaire",
    value: "/questionnaire",
  },
  {
    label: "Subscriptions",
    value: "/subscriptions",
  },
];

export const WALLET_TABS = [
  {
    label: "Service Payments",
    value: "service_payments",
  },
  {
    label: "Session Payments",
    value: "session_payments",
  },
];

export const POPOVER = [
  { label: "Profile Setting", path: "/settings" },
  { label: "Notifications", path: "/notifications" },
  { label: "Support", path: "/support" },
  { label: "Manage Zoom", path: "/manage-zoom" },
  { label: "Crud", path: "/cruds" },
  { label: "Contacts", path: "/contacts" },
  { label: "CMS", path: "/cms/landing-page" },
  { label: "Logout", path: "" },
];

export const PRO_SERVICE_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Requests",
    value: "requests",
  },
];

export const PRO_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Portfolio",
    value: "portfolio",
  },
  {
    label: "Questionnaires",
    value: "questionnaires",
  },
  {
    label: "Services",
    value: "services",
  },
  {
    label: "Jobs",
    value: "jobs",
  },

  {
    label: "Chats",
    value: "chats",
  },
  {
    label: "Referrals",
    value: "referrals",
  },
  {
    label: "Reviews",
    value: "reviews",
  },
  {
    label: "Sessions",
    value: "sessions",
  },
  {
    label: "Service Categories",
    value: "categories",
  },
];
export const SESSION_DETAIL_PAGE_TAB = [
  { label: "Requests", value: "requests" },
  { label: "Recordings", value: "recordings" },
];

export const PRO_HEADINGS = {
  heading: "Professionals Verification",
  email: "Email",
  status: "Status",
};

export const RELATED_SOLUTION = [
  {
    id: 1,
    heading1: "Industries",
    text1: "Cannabis Law",
    text2: "Life Sciences",
    text3: "Mining",
    text4: "Private Client Services",
  },
  {
    id: 2,
    heading2: "Practices",
    text1: "Cannabis Law",
    text2: "Life Sciences",
    text3: "Mining",
    text4: "Private Client Services",
  },
  {
    id: 3,
    heading3: "Markets",
    text1: "Cannabis Law",
    text2: "Life Sciences",
  },
];

export const Referral = [
  {
    label: "Clients",
    value: "user",
  },
  {
    label: "Professionals",
    value: "professionals",
  },
];

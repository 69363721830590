import React from "react";
import classes from "./AppDivider.module.css";

const AppDivider = ({ width = "", variant = "dark" }) => {
  return (
    <div
      className={[classes.container, classes[variant]].join(" ")}
      style={{ width: width ? width : "100%" }}
    />
  );
};

export default AppDivider;

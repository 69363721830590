export const NOTIFICATION_DATA = [
  {
    id: 1,
    image: "/images/Avatar.png",
    name: "Oscar",
    email: "oscar@email.com",
  },
  {
    id: 2,
    image: "/images/Avatar.png",
    name: "Daniel",
    email: "daniel@email.com",
  },
  {
    id: 3,
    image: "/images/Avatar.png",
    name: "Mark",
    email: "mark@email.com",
  },
];

export const SESSIONS_TAB_DATA = {
  session: "One-to-One call session",
  subTitle: "Property Estimation",
  date: "Jun 25, 2024",
  time: "07:00 PM",
  price: "$36",
};

export const REQUESTS_CARD_DATA = {
  status: "Pending",
  userName: "Oscar",
  email: "email@oscarfakedomain.net",
  date: "Jun 28, 2024",
  time: "8:00 pm",
  topic: "Property Estimation",
  postedTime: 4,
};

export const TRANSACTION_DATA = [
  {
    id: 1,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 2,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 3,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 4,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 5,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
  {
    id: 6,
    user_name: "Karin Bezuidenhout",
    title: "Estate Planning",
    subtitle: "Property Estimation",
    date: "Aug, 2023",
    price: "$36",
    session: "One-to-One Session",
  },
];

export const WALLET_TABS = [
  {
    label: "Service Payments",
    value: "service_payments",
  },
  {
    label: "Session Payments",
    value: "session_payments",
  },
  {
    label: "Cashback History",
    value: "cashback_history",
  },
];

export const USER_TABS = [
  {
    label: "Details",
    value: "details",
  },
  {
    label: "Jobs",
    value: "jobs",
  },
  {
    label: "Chats",
    value: "chats",
  },
  {
    label: "Sessions",
    value: "sessions",
  },
];

export const STRIPE_CARD = {
  title: "Total Income",
  value: "71,897",
  percent: "122",
  iconimg: "images/logos_stripe.png",
  icontext: "Account Configured",
  label1: "Email:",
  label2: "Country",
  user: "clientname@example.com",
  country: "United States",
};

export const REWARDS = [
  {
    id: 1,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 2,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 3,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 4,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 5,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 6,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 7,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
  {
    id: 8,
    image: "/images/reward.png",
    username: "Karin Bezuidenhout",
    title: "Reward Title",
    date: "Jun 25, 2024",
  },
];

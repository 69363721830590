import React from "react";
import { Container } from "react-bootstrap";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import AppDivider from "../../component/atoms/AppDivider";
import { Button } from "../../component/atoms/Button/Button";
import FormWithHeading from "../../component/molecules/FormWithHeading/FormWithHeading";
import Header from "../../component/Header/Header";

export default function CreateSubAdmin() {
  const navigate = useNavigate();
  return (
    <div>
      <Header />
      <Container className="mt-3">
        <Button
          label={"Back"}
          leftIcon={<IoArrowBack />}
          variant="secondary"
          onClick={() => navigate(-1)}
        />
      </Container>

      <AppDivider variant="light" />

      <Container className="mt-3">
        <FormWithHeading />
      </Container>
    </div>
  );
}

import { IoLocationOutline } from "react-icons/io5";
import { LuMail } from "react-icons/lu";
import { MdOutlineLocalPhone } from "react-icons/md";
import classes from "./AdviserCard.module.css";
import { mediaUrl } from "../../../resources/utils/helper";
import { Delete } from "@mui/icons-material";
import { RiEditLine } from "react-icons/ri";
import {
  GetCountries
} from "react-country-state-city";
import { useEffect } from "react";

export default function AdviserCard({ item, handleDelete = () => {}, handleEdit = () => {} }) {
  let country = "";
  console.log("🚀 ~ defaultfunctionAdviserCard({item,handleDelete ~ country:", country)

  useEffect(() => {
      GetCountries().then((result) => {
        const formattedCountries = result.map((country) => ({
          label: country.name,
          value: country.id,
        }));
  
        if (item?.locationCountry) {
          const selectedCountry = formattedCountries.find(
            (country) => country.value === parseInt(item?.locationCountry)
          );
          if (selectedCountry) {
            country = selectedCountry;
          }
        }
      });
      
    }, []);
  
  return (
    <div
      className={classes.card}
      //onClick={() => {}}
    >
      <div className={classes.imageDiv}>
        <img
          src={item?.image ? mediaUrl(item?.image) : "/images/profileDummy.jpg"}
          alt="image"
          width={260}
          height={260}
        />
      </div>

      <div className={classes.contentDiv}>
        <div className={classes.head}>
          <h3>
            {item?.firstName + " " + item?.lastName || "Alexandra Mitretodis"}
          </h3>
          <div className={classes.actions}>
          <RiEditLine
            size={28}
            className="curser-pointer"
            onClick={handleEdit}
          />
          <Delete size={28} className="curser-pointer" onClick={handleDelete} />
          </div>
        </div>
        <p className={classes.position}>{item?.position || "PARTNER"}</p>
        <div className={classes.contacts}>
          <div className={classes.contactDiv}>
            <IoLocationOutline />
            <p>{item?.locationCityName + ", " + item?.locationCountryName || "N/A"}</p>
          </div>
          <div className={classes.contactDiv}>
            <MdOutlineLocalPhone />
            <p>{item?.phone || "N/A"}</p>
          </div>
          <div className={classes.contactDiv}>
            <LuMail />
            <p>{item?.email || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

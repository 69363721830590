import { BiSolidEditAlt } from "react-icons/bi";
import { FaArrowRightLong, FaUserGroup } from "react-icons/fa6";
import {
  MdAccessTime,
  MdDeleteOutline,
  MdOutlineDateRange,
  MdOutlineFileDownload,
} from "react-icons/md";
import { mergeClass } from "../../../utils/helper";
import { Button } from "../../atoms/Button/Button";
import classes from "./SessionCard.module.css";

const SessionCard = ({
  data,
  showIcon,
  showDate,
  showMember,
  showAmount,
  onClick,
  buttonLabel,
  showLink,
  sessionTitleClass,
  serviceInfoClass,
}) => {
  return (
    <div className={classes.service_cards_container}>
      <div className={classes.card_main_div}>
        <div className={classes.user_div}>
          <div>
            <h4 className={sessionTitleClass}>{data?.session}</h4>
          </div>
          {showIcon && (
            <div className={classes.user_icon}>
              <BiSolidEditAlt size={25} color="#36696B" />
              <MdDeleteOutline size={25} color="#36696B" />
            </div>
          )}
        </div>
        <div className={classes.service_card}>
          <div className={classes.subDiv}>
            <div className="">
              <h5>{data?.subTitle}</h5>
              <div
                className={mergeClass(serviceInfoClass, classes.serviceInfo)}
              >
                {showDate && (
                  <div className={classes.timeDiv}>
                    <MdOutlineDateRange size={20} />
                    <span className={classes.dateAndTime}>{data?.date}</span>
                  </div>
                )}
                {showMember && (
                  <p className="d-flex align-items-center gap-2 mt-3">
                    <FaUserGroup size={20} />
                    <b
                      style={{
                        color: "#2F5456",
                        fontFamily: "var(--ff-primary-bold)",
                      }}
                    >
                      {data?.member}
                    </b>
                  </p>
                )}

                <div className={classes.timeDiv}>
                  <MdAccessTime size={20} />
                  <span className={classes.dateAndTime}>{data?.time}</span>
                </div>
              </div>
            </div>
          </div>

          <div
            className={mergeClass(
              classes.bottomSubDiv,
              showLink && classes.bottomSubDivTwo
            )}
          >
            <div className={classes.subDivAmount}>
              {showAmount && (
                <div className={classes.pricingDiv}>
                  <p>Amount</p>
                  <h5>{data?.price}</h5>
                </div>
              )}
            </div>
            <div className={classes.subDivBtn}>
              {showLink && (
                <div className={classes.pricingDiv}>
                  <Button className={classes.reqDownload}>
                    <MdOutlineFileDownload
                      size={24}
                      color="var(--dark-gray-color)"
                    />{" "}
                    Download
                  </Button>
                </div>
              )}

              {buttonLabel && (
                <div className={classes.btngDiv} onClick={onClick}>
                  <Button
                    variant="primary"
                    label={buttonLabel}
                    className={classes.btn}
                  >
                    <FaArrowRightLong />
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;

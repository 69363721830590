import { useState, useEffect } from "react";
import classes from "./ChatComponent.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { mergeClass } from "../../utils/helper";
import ChatRoom from "./ChatRoom";
import useDebounce from "../../resources/hooks/useDebounce";
import SearchInput from "../molecules/SearchInput";
import ChattingCard from "./ChattingCard";
import { chatIcon } from "../../constant/imagePath";
import { useSelector } from "react-redux";
import { Get } from "../../interceptor/axiosInterceptors";
import Content from "../atoms/Content/Content";
import { baseURL } from "../../resources/utils/helper";

export default function ChatComponent({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [activeRoomId, setActiveRoomId] = useState(null);
  const [search, setSearch] = useState("");
  const [loadingRooms, setLoadingRooms] = useState(false);
  const [rooms, setRooms] = useState([]);

  const debouncedSearch = useDebounce(search, 500);

  const handleRoomClick = (id) => {
    setActiveRoomId(id);
  };

  const handleBackClick = () => {
    setActiveRoomId(null);
  };

  const getRooms = async ({ search = "" }) => {
    setLoadingRooms(true);

    const res = await Get(
      baseURL(`chats?userId=${slug}&search=${search}`),
      accessToken,
      false
    );

    if (res) {
      setRooms(res?.data?.data);
    }

    setLoadingRooms(false);
  };

  useEffect(() => {
    getRooms({ search: debouncedSearch });
  }, [debouncedSearch]);

  return (
    <div className={classes.mainDiv}>
      <Container>
        <Row>
          <Col
            xs={activeRoomId !== null ? 12 : 0}
            md={activeRoomId !== null ? 5 : 0}
            lg={activeRoomId !== null ? 3 : 0}
            className={
              activeRoomId !== null ? classes.hiddenOnSmallScreens : ""
            }
          >
            <SearchInput
              customStyle={{ width: "100%" }}
              value={search}
              setter={setSearch}
            />

            <Content
              {...{
                loading: loadingRooms,
                data: rooms,
                noDataText: "No Rooms Found",
              }}
            >
              <div className={mergeClass("scrollbar", classes.chatRoomMainDiv)}>
                <Row className="gy-3">
                  {rooms?.map((e, index) => (
                    <Col key={index} md={12}>
                      <ChatRoom
                        data={e}
                        isActive={activeRoomId === e?.chatName}
                        onClick={() => handleRoomClick(e?.chatName)}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </Content>
          </Col>

          <Col
            xs={activeRoomId !== null ? 12 : 0}
            md={activeRoomId !== null ? 7 : 7}
            lg={activeRoomId !== null ? 9 : 9}
            className={
              activeRoomId !== null ? classes.chattingCardContainer : ""
            }
          >
            {activeRoomId !== null ? (
              <ChattingCard
                onBackClick={handleBackClick}
                showInput={false}
                chatNameSelectedRoom={activeRoomId}
                senderId={slug}
              />
            ) : (
              <div className={classes.chattingCardHidden}>
                <img
                  className={classes.chatIcon}
                  src={chatIcon}
                  alt="chat icon"
                  width={150}
                  height={150}
                />
                <span
                  className={mergeClass(classes.userName, classes.subtitle)}
                >
                  Select a Chat to start chatting
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import React from "react";
import { Container } from "react-bootstrap";
import { FaArrowLeftLong, FaRegCircleUser } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { mergeClass } from "../../../utils/helper";
import { Button } from "../../atoms/Button/Button";
import TabsComponent from "../TabsComponent/TabsComponent";
import classes from "./BackButton.module.css";

const BackButton = ({
  title = "Back",
  className,
  rightIcon,
  profile,
  tabs = [],
  setTab,
  tab,
}) => {
  const { user } = useSelector((state) => state?.authReducer);

  const goBack = () => {
    window.history.back();
  };

  return (
    <Container>
      <div
        className={mergeClass(
          classes.mainDiv,
          profile || tab ? classes.mainDivTwo : ""
        )}
      >
        <Button
          onClick={goBack}
          variant="secondary"
          className={className}
          rightIcon={rightIcon}
          leftIcon={<FaArrowLeftLong />}
        >
          {title}
        </Button>
        {profile && (
          <div className={classes.profileDiv}>
            <div className={classes.imageDiv}>
              <img
                alt=""
                src={
                  user?.profileImagePath || (
                    <FaRegCircleUser
                      size={"30"}
                      color="var(--user-icon-color)"
                    />
                  )
                }
                className={classes.image}
              />
            </div>
            <div className={classes.emailAndName}>
              <span
                className={mergeClass(
                  classes.font,
                  classes.userName,
                  "maxLine1"
                )}
              >
                {user?.firstName + " " + user?.lastName}
              </span>
              <span
                className={mergeClass(classes.font, classes.email, "maxLine1")}
              >
                {user?.email}
              </span>
            </div>
          </div>
        )}
        {tab && <TabsComponent tab={tab} tabs={tabs} setTab={setTab} />}
      </div>
    </Container>
  );
};

export default BackButton;

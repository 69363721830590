import React from "react";
import classes from "./Curds.module.css";
import { BsArrowUpRight } from "react-icons/bs";
import Header from "../../component/Header/Header";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProfileNameEmail from "../../component/molecules/ProfileNameEmail/ProfileNameEmail";

export default function Curds() {
  const navigate = useNavigate();

  // Dummy data for the cards
  const data = [
    {
      id: 1,
      title: "Wealth & Health Protection Crud",
      route: "/wealth-health-protection",
    },
    {
      id: 2,
      title: "Protection Solution Crud",
      route: "/protection-solutions",
    },
    {
      id: 3,
      title: "Advanced Education",
      route: "/protection-solutions?category=education",
    },
    {
      id: 4,
      title: "News",
      route: "/news",
    },
    {
      id: 5,
      title: "Markets",
      route: "/markets",
    },
    {
      id: 5,
      title: "Advisers",
      route: "/advisers",
    },
  ];

  return (
    <>
      <Header />
      <Container>
        <ProfileNameEmail />
        <h5 className="my-4">Crud</h5>

        {data.map((item) => (
          <div key={item.id} className={classes.Main}>
            <div>
              <p>{item.title}</p>
            </div>
            <div>
              <BsArrowUpRight
                onClick={() => navigate(item.route)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        ))}
      </Container>
    </>
  );
}

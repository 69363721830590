import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Get, Put } from "../../interceptor/axiosInterceptors";
import Content from "../../component/atoms/Content/Content";
import Header from "../../component/Header/Header";
import LoadMore from "../../component/molecules/LoadMore/LoadMore";
import ProfileNameEmail from "../../component/molecules/ProfileNameEmail/ProfileNameEmail";
import RenderToast from "../../component/molecules/RenderToast";
import WealthHealthCard from "../../component/molecules/WealthHealthCard";
import SubHeader from "../../component/organisms/SubHeader/SubHeader";
import { baseURL } from "../../resources/utils/helper";
import { RECORDS_LIMIT } from "../../data/constants";
import useDebounce from "../../resources/hooks/useDebounce";
import AreYouSureModal from "../../modals/AreYouSureModal";
import { apiHeader } from "../../utils/helper";
import classes from "./WealthAndHealthProtection.module.css";

export default function WealthAndHealthProtection() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(""); // loading, loadMore, deleteLoading
  const [modal, setModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 500);

  const getData = async ({
    PageNumber = page,
    loadMore = false,
    search = searchDebounce,
  }) => {
    const query = {
      detailTypeId: 1,
      PageSize: RECORDS_LIMIT,
      search: search,
      PageNumber,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`LandingPageDetail?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data?.data?.data;
      setPage(PageNumber);
      // setTotalPages(Math.ceil(data?.totalItems / RECORDS_LIMIT));
      setData((prev) => {
        const _data = data?.healthAndWealthProtection || [];
        if (loadMore) return [...prev, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

  const handleDeleteItem = async () => {
    setLoading("deleteLoading");
    const response = await Put(
      baseURL(`LandingPageDetail/delete?id=${selectedItem?.id}`),
      {},
      apiHeader(accessToken)
    );
    if (response) {
      RenderToast({
        type: "success",
        message: `Successfully Deleted`,
      });
      await getData({
        PageNumber: page,
        search: searchDebounce,
        loadMore: page > 1,
      });
    }
    setModal(false);
    setLoading("");
  };

  useEffect(() => {
    getData({
      PageNumber: 1,
    });
  }, [searchDebounce]);

  return (
    <div className={classes.mainDiv}>
      <Header />
      <ProfileNameEmail
        onClick={() => navigate("/add-edit-health-protection")}
        buttonLabel="Create Wealth & Health Protection"
        showBtn
      />
      <Container className="globalContainer">
        <Row className="mt-4">
          <Col>
            <div className={classes.subHeadClass}>
              <SubHeader
                title={"Wealth & Health Protection"}
                showSearch
                setSearch={setSearch}
                search={search}
              />
            </div>
            <div className={classes.reqCardClass}>
              <Content
                {...{
                  data,
                  noDataText: "No Data Found",
                  loading: loading === "loading",
                }}
              >
                <Row className="pb-5">
                  {data.map((item, index) => {
                    return (
                      <Col key={item?.id || index} lg={6} md={6} sm={12}>
                        <WealthHealthCard
                          data={item}
                          handleEdit={() => {
                            navigate(`/add-edit-health-protection/${item?.id}`);
                          }}
                          handleDelete={() => {
                            setModal(true);
                            setSelectedItem(item);
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
                <LoadMore
                  {...{
                    page,
                    totalPages,
                    loading: loading === "loadMore",
                    setPage: () => {
                      const _page = page + 1;
                      getData({ loadMore: true, PageNumber: _page });
                    },
                  }}
                />
              </Content>
            </div>
          </Col>
        </Row>

        {modal && (
          <AreYouSureModal
            show={modal}
            setShow={setModal}
            subTitle={`Are you sure you want to Delete ${selectedItem?.title} ?`}
            onClick={handleDeleteItem}
            loading={loading === "deleteLoading"}
          />
        )}
      </Container>
    </div>
  );
}

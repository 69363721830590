import { RiEditLine } from "react-icons/ri";
import classes from "./ProtectionSolutionCard.module.css";
import { Delete } from "@mui/icons-material";

const ProtectionSolutionCard = ({ data, handleDelete, handleEdit }) => {
  return (
    <div className={classes.mainContainer}>
      <div className={classes.mainImageContainer}>
        <div className={classes.iconContainer}>
          <img src={data?.icon} alt="Icon" />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <h6 className={"maxLine1 mb-4 mt-4"}>{data?.title}</h6>
        <div>
          <RiEditLine
            size={28}
            className="curser-pointer"
            onClick={handleEdit}
          />
          <Delete size={28} className="curser-pointer" onClick={handleDelete} />
        </div>
      </div>

      <p>{data?.description}</p>
    </div>
  );
};

export default ProtectionSolutionCard;

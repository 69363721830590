import React, { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Put } from "../../../interceptor/axiosInterceptors";
import { baseURL } from "../../../resources/utils/helper";
import { DemoProfile } from "../../../constant/imagePath";
import AreYouSureModal from "../../../modals/AreYouSureModal";
import { apiHeader } from "../../../utils/helper";
import { Button } from "../../atoms/Button/Button";
import { ProfileWithEditButton } from "../../organisms/ProfileWithEditButton";
import styles from "./ProfileCard.module.css";

const ProfileCard = ({ showButton = false, isEditPen = true, data = null }) => {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [uploadImg, setUploadImg] = useState();
  const [selectedItem, setSelectedItem] = useState(false);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(data?.isBlocked);

  const changeStatus = async () => {
    setLoading("status");
    const res = await Put(
      baseURL(`User/status`),
      {
        userId: data?.id,
        status: !status,
      },
      apiHeader(accessToken)
    );
    if (res) {
      setStatus((prev) => !prev);
      setSelectedItem(false);
    }
    setLoading("");
  };

  return (
    <>
      <Card className={styles.profileCard}>
        <Card.Body>
          <Row className="align-items-center">
            <Col xs={12} sm={6} className={styles.image}>
              <ProfileWithEditButton
                isEdit={isEditPen}
                onClick={setUploadImg}
                setUpdateImage={setUploadImg}
                updateImage={
                  uploadImg || data?.profileImagePath === null
                    ? DemoProfile
                    : data?.profileImagePath
                }
              />
            </Col>
            <Col xs={12} sm={6} className={styles.profBtn}>
              {showButton && (
                <Button
                  className={styles.btn}
                  variant={status ? "secondary" : "primary"}
                  label={!status ? "Disable" : "Enable"}
                  onClick={() => {
                    setSelectedItem(true);
                  }}
                />
              )}
            </Col>
            <Col sm={6} xs={12}>
              <Card.Title className="mt-4">{`${data?.firstName} ${data?.lastName}`}</Card.Title>
            </Col>
          </Row>
          {data?.userRoleId == "3" && (
            <Row>
              <Col md={7}>
                <Card.Text className={styles.profileDescription}>
                  {data?.description || "N/A"}
                </Card.Text>
              </Col>
            </Row>
          )}
          <div className={styles.profileContact}>
            <p>{data?.phone || `N/A`}</p>
            <p>{data?.email || `N/A`}</p>
          </div>
        </Card.Body>
      </Card>

      {selectedItem && (
        <AreYouSureModal
          show={selectedItem}
          setShow={setSelectedItem}
          subTitle={`Are you sure you want to ${
            !status ? "Disable" : "Enable"
          } this account?`}
          onClick={changeStatus}
          loading={loading === "status"}
        />
      )}
    </>
  );
};

export default ProfileCard;

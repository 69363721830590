import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { Delete, Post, Put } from "../../interceptor/axiosInterceptors";
import { Button } from "../../component/atoms/Button/Button";
import { Input } from "../../component/atoms/Input/Input";
import RenderToast from "../../component/molecules/RenderToast";
import { apiHeader } from "../../utils/helper";
import ModalSkeleton from "../ModalSkeleton";
import classes from "./AddQuestionModal.module.css";
import { baseURL } from "../../resources/utils/helper";

export default function AddQuestionModal({
  show,
  setShow,
  data = null,
  callback = () => {},
}) {
  const accessToken = useSelector((state) => state?.authReducer?.accessToken);

  const [questionText, setQuestionText] = useState(data?.questionText || "");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(
    data
      ? data?.option
      : [{ optionName: "" }, { optionName: "" }, { optionName: "" }]
  );

  const handleOptionChange = (index, newOptionName) => {
    const updatedOptions = [...options];
    updatedOptions[index].optionName = newOptionName;
    setOptions(updatedOptions);
  };

  const renderBtnText = () => {
    if (data) return loading ? "Updating..." : "Update";
    return loading ? "Adding..." : "Add";
  };

  const handleAddOption = () => {
    setOptions((prev) => [...prev, { optionName: "" }]);
  };

  const removeAddOption = async (index, item) => {
    if (options?.length === 2) return;

    const _options = options?.filter((_, idx) => index !== idx);
    setOptions(_options);

    if (item?.id) {
      callback();
      await Delete(
        baseURL(`Option?id=${item?.id}`),
        null,
        apiHeader(accessToken)
      );
    }
  };

  const onAdd = async () => {
    const params = {
      questionnaireId: 1,
      questions: [
        {
          questionText,
          options,
        },
      ],
    };

    setLoading(true);

    const response = await Post(
      baseURL("Question"),
      params,
      apiHeader(accessToken)
    );

    if (response) {
      callback();
      setShow(false);
      RenderToast({ message: "Successfully Added", type: "success" });
    }

    setLoading(false);
  };

  const onEdit = async () => {
    // On Option Add
    const newOptions = options?.filter((item) => !item?.id);

    let promiseAddOptions = [];

    if (newOptions?.length) {
      promiseAddOptions = newOptions?.map((item) =>
        Post(
          baseURL("Option"),
          { ...item, questionId: data?.id },
          apiHeader(accessToken)
        )
      );
    }

    // on Edit Options
    const optionsExists = options?.filter((item) => item?.id);
    let promiseOptionEdit = optionsExists?.map((item) =>
      Put(baseURL("Option"), item, apiHeader(accessToken))
    );

    // on Edit Question
    let promiseQuestionEdit = [];
    if (data?.questionText !== questionText) {
      promiseQuestionEdit = [
        Put(
          baseURL("Question"),
          { questionText, id: data?.id },
          apiHeader(accessToken)
        ),
      ];
    }

    setLoading(true);

    const resp = await Promise.allSettled([
      ...promiseAddOptions,
      ...promiseQuestionEdit,
      ...promiseOptionEdit,
    ]);

    if (resp?.length) {
      callback();
    }

    setLoading(false);
    setShow(false);
  };

  const handleSubmit = async () => {
    if (!questionText) {
      return RenderToast({
        message: "Please add Question",
        type: "warn",
      });
    }

    let isReturned = false;

    options?.forEach((option) => {
      if (!option?.optionName) {
        isReturned = true;
        return RenderToast({
          message: "Please add all options",
          type: "warn",
        });
      }
    });

    if (isReturned) return;

    if (data) {
      onEdit();
    } else {
      onAdd();
    }
  };

  return (
    <ModalSkeleton
      setShow={setShow}
      show={show}
      width={"600px"}
      header={`${data ? "Update" : "Add"} a Question`}
      showCloseIcon
    >
      <Row className={classes.options}>
        <Col md={12} className="">
          <Input
            type="text"
            value={questionText}
            setter={setQuestionText}
            placeholder={"Question"}
          />
        </Col>
      </Row>

      {options?.map((item, index) => {
        return (
          <Row className="mt-3" key={index}>
            <Col md={11}>
              <Input
                type="text"
                value={item?.optionName}
                placeholder={"Option"}
                setter={(newOptionName) =>
                  handleOptionChange(index, newOptionName)
                }
              />
            </Col>

            <Col md={1} className="d-flex align-items-center gap-2">
              {options?.length !== 2 && (
                <RiDeleteBin6Fill
                  fontSize={20}
                  onClick={() => removeAddOption(index, item)}
                />
              )}
            </Col>
          </Row>
        );
      })}

      <Row className="mt-2">
        <Col md={10} className={classes.addOptionCont}>
          <span onClick={handleAddOption}>Add Option</span>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col md={6}>
          <Button
            variant="primary"
            className={"w-100"}
            onClick={() => setShow(false)}
            disabled={loading}
          >
            Cancel
          </Button>
        </Col>
        <Col md={6}>
          <Button
            variant="secondary"
            onClick={handleSubmit}
            disabled={loading}
            className={"w-100"}
          >
            {renderBtnText()}
          </Button>
        </Col>
      </Row>
    </ModalSkeleton>
  );
}

import React from "react";
import classes from "./noData.module.css";
import { ImSearch } from "react-icons/im";

function NoData({
  text = "No Data Found",
  className,
  showIcon = true
}) {
  return (
    <div
      className={[classes.noDataContainer, className && className].join(" ")}
    >
      {showIcon && <ImSearch size={60} color={"var(--black-blue)"} />}
      <p>{text}</p>
    </div>
  );
}

export default NoData;

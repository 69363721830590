import React from "react";
import ReactQuill from "react-quill";
import classes from "./QuillInput.module.css";
import "react-quill/dist/quill.snow.css";

function QuillInput({
  value,
  setter,
  quillClass = "",
  placeholder = "",
  label,
  customDiv,
}) {
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        {
          color: [
            "white",
            "black",
            "red",
            "blue",
            "skyblue",
            "teal",
            "brown",
            "green",
            "orange",
            "pink",
            "gray",
            "purple",
            "maroon",
            "yellow",
          ],
        },
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      // ["link", "image"],
      ["link"],
      ["clean"],
    ],
  };
  return (
    <>
      {label && <label className={classes.label}>{label}</label>}
      <div className={`${classes.quillInput} ${customDiv}`}>
        <ReactQuill
          className={`${classes.quill} ${quillClass} custom-quill`}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setter(e)}
          modules={modules}
        />
      </div>
    </>
  );
}

export default QuillInput;

import React, { useState } from 'react';
import { IoMdTime } from "react-icons/io";
import { MdOutlineAccountCircle } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { Put } from '../../../interceptor/axiosInterceptors';
import { apiHeader, getLocalTime } from '../../../utils/helper';
import RenderToast from '../RenderToast';
import Statuses from '../../atoms/Status';
import classes from "./SupportCard.module.css";
import { baseURL } from '../../../resources/utils/helper';

export default function SupportCard({ data, getData }) {
  const { accessToken, user } = useSelector((state) => state?.authReducer);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleChangeStatus = async ({ status = "" }) => {
    setLoading(true);

    const response = await Put(
      baseURL(`SupportTicket`),
      { id: data?.id, ticketStatusId: status },
      apiHeader(accessToken)
    );

    if (response) {
      RenderToast({
        type: "success",
        message: `${
          status == 1
            ? "This request han been closed"
            : "This request han been rejected"
        }`,
      });
      getData({});
    }

    setLoading(false);
  };

  return (
    <div className={classes.SupportCard}>
      <div className={classes.statusDiv}>
        <Statuses status={data?.ticketStatus?.toLowerCase()} />
      </div>
      <div className={classes.user_div}>
        <div>
          <MdOutlineAccountCircle size={35} color="#36696B" />
        </div>
        <h4>
          <u>{data?.name}</u>
        </h4>
        <p>{data?.email}</p>
      </div>
      <p className={classes.description}>{data?.description}</p>
      <hr />
      <div className={classes.footer}>
        <div className={classes.dateDiv}>
          <IoMdTime />
          <p className={classes.time}>
            {
              //moment(data?.time).format("lll")
              getLocalTime(data?.time)
            }
          </p>
        </div>
        <p
          className={classes.chat}
          onClick={() =>
            navigate(
              `/support-chat?supportId=${data?.id}&requesterId=${data?.userId}&SenderId=${user?.id}&status=${data?.ticketStatus}`
            )
          }
        >
          Chat
        </p>
        {data?.ticketStatus?.toLowerCase() == "pending" && (
          <>
            <p
              className={classes.chat}
              disabled={loading}
              onClick={() => handleChangeStatus({ status: 1 })}
            >
              Mark as resolved
            </p>
            <p
              className={classes.chat}
              disabled={loading}
              onClick={() => handleChangeStatus({ status: 3 })}
            >
              Reject
            </p>
          </>
        )}
      </div>
    </div>
  );
}

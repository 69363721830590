import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { Button } from "../../component/atoms/Button/Button";
import { Input } from "../../component/atoms/Input/Input";
import { TextArea } from "../../component/atoms/TextArea";
import Header from "../../component/Header/Header";
import UploadImageBox from "../../component/molecules/UploadImageBox";
import { baseURL } from "../../resources/utils/helper";
import classes from "./HomePageCMS.module.css";

import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { apiHeader } from "../../utils/helper";
import { Get, Put } from "../../interceptor/axiosInterceptors";

const HomePageCMS = () => {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState("get");

  const getData = async () => {
    setIsLoading("get");

    const res = await Get(baseURL(`LandingPageContents`), false, false);

    if (res) {
      const data = res?.data?.data;
      setData(data);
    }

    setIsLoading("");
  };
  useEffect(() => {
    getData();
  }, []);

  //   handleSubmit
  const handleSubmit = async () => {
    const imageFile = data?.hero?.image instanceof File;
    const file = data?.hero?.image;
    if (imageFile) {
      delete data?.hero?.image;
    }
    setIsLoading("post");
    const formData = new FormData();
    if (imageFile) {
      formData.append("Image", file);
    }

    formData.append("hero.subTitle", data.hero.subTitle);
    formData.append("hero.heading", data.hero.heading);
    formData.append("hero.description", data.hero.description);
    formData.append("hero.buttonText", data.hero.buttonText);

    formData.append(
      "healthAndWealthSection.heading",
      data.healthAndWealthSection.heading
    );
    formData.append(
      "healthAndWealthSection.description",
      data.healthAndWealthSection.description
    );

    formData.append(
      "realTimeProtectionSolutionSection.tagLine",
      data.realTimeProtectionSolutionSection.tagLine
    );
    formData.append(
      "realTimeProtectionSolutionSection.heading",
      data.realTimeProtectionSolutionSection.heading
    );

    formData.append(
      "realTimeProtectionSolutionSection.description",
      data.realTimeProtectionSolutionSection.description
    );
    formData.append(
      "letsConnectSection.heading",
      data.letsConnectSection.heading
    );
    formData.append(
      "letsConnectSection.description",
      data.letsConnectSection.description
    );
    formData.append(
      "advanceEducationToTheIndustrySection.description",
      data.advanceEducationToTheIndustrySection.description
    );
    formData.append(
      "advanceEducationToTheIndustrySection.heading",
      data.advanceEducationToTheIndustrySection.heading
    );

    const res = await Put(
      baseURL(`LandingPageContent`),
      formData,
      apiHeader(accessToken, true)
    );
    if (res) {
      toast.success("Data Saved Successfully");
    }
    setIsLoading("");
  };

  //   handleUpdateKey
  const handleUpdateKey = ({ value, key, innerKey }) => {
    setData({
      ...data,
      [key]: {
        ...data[key],
        [innerKey]: value,
      },
    });
  };

  return (
    <>
      <Header />
      <div className={classes.content}>
        <Container className={classes.main}>
          {isLoading == "get" ? (
            <Skeleton variant="rectangular" width={"100%"} height={800} />
          ) : (
            <Row className={classes.row}>
              <Col md={12}>
                <div className={classes.spBtw}>
                  <h6>Landing Page</h6>
                </div>
              </Col>
              <Col md={12}>
                {Object.keys(data).map((key) => {
                  return (
                    <div key={key} className={classes.section}>
                      <p>{getReadableKey(key)}</p>
                      <div className={classes.sectionContent}>
                        {Object.keys(data[key]).map((innerKey) => {
                          const Component = InputComponent(innerKey);
                          return (
                            <div
                              key={innerKey}
                              className={classes.sectionInner}
                            >
                              <Component
                                placeholder={innerKey}
                                label={getReadableKey(innerKey)}
                                value={data[key][innerKey]}
                                state={data[key][innerKey]}
                                setter={(value) =>
                                  handleUpdateKey({ value, key, innerKey })
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}

                <div className="d-flex justify-content-end mt-3 mb-5">
                  <Button
                    label={isLoading ? "Saving..." : "Save"}
                    disabled={isLoading}
                    variant="secondary"
                    onClick={handleSubmit}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </>
  );
};

export default HomePageCMS;

const InputComponent = (key) => {
  if (key === "description") {
    return TextArea;
  }
  if (key === "image") {
    return UploadImageBox;
  }
  return Input;
};

const _initialData = {
  hero: {
    subTitle: "Lorem Imsum",
    heading: "Lorem Ipsum",
    description:
      "Lorem Ipsum Lorem Ipsum dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    buttonText: "Lorem Ipsum",
    image:
      "https://fastly.picsum.photos/id/6/5000/3333.jpg?hmac=pq9FRpg2xkAQ7J9JTrBtyFcp9-qvlu8ycAi7bUHlL7I",
  },
  healthAndWealthSection: {
    heading: "Lorem  Ipsum",
    description:
      "Lorem Ipsum Lorem Ipsum dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  realTimeProtectionSolutionSection: {
    tagLine: "Lorem Ipsum",
    heading: "Lorem Ipsum",
    description:
      "Lorem Ipsum Lorem Ipsum dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  advanceEducationToTheIndustrySection: {
    heading: "Lorem Ipsum",
    description:
      "Lorem Ipsum Lorem Ipsum dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  letsConnectSection: {
    heading: "Lorem Ipsum",
    description:
      "Lorem Ipsum Lorem Ipsum dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
};

const getReadableKey = (key) => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { Get, Post, Put } from "../../interceptor/axiosInterceptors";
import Header from "../../component/Header/Header";
import { Input } from "../../component/atoms/Input/Input";
import BackButton from "../../component/molecules/BackButton/BackButton";
import { Loader } from "../../component/molecules/Loader";
import RenderToast from "../../component/molecules/RenderToast";
import UploadImageBox from "../../component/molecules/UploadImageBox";
import SubHeader from "../../component/organisms/SubHeader/SubHeader";
import { baseURL } from "../../resources/utils/helper";
import { apiHeader, createFormData, mergeClass } from "../../utils/helper";
import classes from "./AddEditMarket.module.css";
import QuillInput from "../../component/molecules/QuillInput";
import NoData from "../../component/atoms/NoData/NoData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

export default function AddEditMarket() {
  const slug = useParams()?.slug;
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [loading, setLoading] = useState(""); // getData, submitData
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
    countryName: "",
    videoUrl: "",
    image: null,
  });
  console.log("🚀 ~ AddEditMarket ~ formData:", formData)

  const toggleOption = () => {
    setIsVideoSelected((prevState) => !prevState);
  };

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (
      !formData.title ||
      !formData.description ||
      !formData.subtitle ||
      !formData.countryName
    ) {
      return RenderToast({
        type: "error",
        message: "All fields are required.",
      });
    }

    if (!formData.image && !formData.videoUrl) {
      return RenderToast({
        type: "error",
        message: "Either image or video is required.",
      });
    }

    if (validator.isNumeric(formData.title)) {
      return RenderToast({
        type: "error",
        message: "Title cannot be a number.",
      });
    }

    const subtitleWordCount = formData.subtitle.trim().split(/\s+/).length;
    if (subtitleWordCount > 50) {
      return RenderToast({
        type: "error",
        message: "Subtitle cannot have more than 50 words.",
      });
    }

    if (
      formData.videoUrl &&
      !/^(https:\/\/(www\.)?youtube\.com\/watch\?v=|https:\/\/youtu\.be\/)/.test(
        formData.videoUrl
      )
    ) {
      return RenderToast({
        type: "error",
        message: "Please provide a valid YouTube video URL.",
      });
    }

    const obj = {...formData};
    if(obj.image && obj.videoUrl){
     isVideoSelected? obj.image = null:obj.videoUrl = ""
    }

    setLoading("submitData");

    const form_data = createFormData(obj);
    let cusFun = slug ? Put : Post;
    const res = await cusFun(
      baseURL(`MarketsPage`),
      form_data,
      apiHeader(accessToken, true)
    );
    if (res) {
      RenderToast({
        type: "success",
        message: `Market ${slug ? "Updated" : "Created"} successfully.`,
      });
      navigate(-1);
    }

    setLoading("");
  };

  const getData = async () => {
    const url = baseURL(`MarketsPage?id=${slug}`);
    setLoading("getData");

    const response = await Get(url, accessToken);

    if (response) {
      const data = response?.data?.data;
      setFormData({
        title: data?.title,
        subtitle: data?.subtitle,
        description: data?.description,
        image: data?.image,
        countryName: data?.countryName,
        videoUrl: data?.videoUrl,
        id: data?.id,
      });

      if (data?.videoUrl) {
        setIsVideoSelected(true);
      } else {
        setIsVideoSelected(false);
      }
    }

    setLoading("");
  };

  useEffect(() => {
    if (slug) getData();
  }, []);

  if (loading == "getData") {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <BackButton title={"Back"} />
      <Container>
        <Row>
          <Col>
            <SubHeader
              title={`${slug ? "Edit" : "Add"} Market`}
              showSearch={false}
            />
            <div className={`${classes.form}`}>
            <div className={classes.togglesDiv}>
            <div className={classes?.optionSwitch}>
              <span>{"Upload Image"}</span>
              <FontAwesomeIcon
                icon={isVideoSelected ? faToggleOff : faToggleOn}
                className={classes.toggleIcon}
                onClick={toggleOption}
              />
            </div>

            <div className={classes?.optionSwitch}>
              <span>{"Provide Video URL"}</span>
              <FontAwesomeIcon
                icon={isVideoSelected ?  faToggleOn : faToggleOff}
                className={classes.toggleIcon}
                onClick={toggleOption}
              />
            </div>
            </div>

              {isVideoSelected ? (
                <>
                  <label className={mergeClass(classes.label, "my-3")}>
                    Video URL
                  </label>
                  <Input
                    type="text"
                    placeholder="Video URL"
                    className={classes.input}
                    value={formData.videoUrl}
                    setter={(value) => handleChange("videoUrl", value)}
                  />
                  {formData?.videoUrl ? (
                    /^(https:\/\/(www\.)?youtube\.com\/watch\?v=|https:\/\/youtu\.be\/)/.test(
                      formData.videoUrl
                    ) ? (
                      <div className={classes.mainVideoContainer}>
                        <iframe
                          width="100%"
                          height="400"
                          src={
                            formData.videoUrl.includes("youtu.be")
                              ? formData.videoUrl
                                  .replace("youtu.be", "youtube.com/embed")
                                  .split("?")[0]
                              : formData.videoUrl.replace("watch?v=", "embed/")
                          }
                          title="Video"
                          frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </div>
                    ) : (
                      <NoData
                        text="Invalid video URL. Please provide a valid YouTube URL."
                        className={classes.videoPreviewContainer}
                      />
                    )
                  ) : (
                    <NoData
                      text="Provide a video URL to preview here."
                      showIcon={false}
                      className={classes.videoPreviewContainer}
                    />
                  )}
                </>
              ) : (
                <>
                  <label className={mergeClass(classes.label, "my-3")}>
                    Upload image
                  </label>
                  <UploadImageBox
                    state={formData.image}
                    setter={(value) => handleChange("image", value)}
                    containerClass={classes.uploadImageContainer}
                  />
                </>
              )}

              <label className={mergeClass(classes.label, "my-3")}>Title</label>
              <Input
                type="text"
                placeholder="Title"
                className={classes.input}
                value={formData.title}
                setter={(value) => handleChange("title", value)}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Sub Title
              </label>
              <Input
                type="text"
                placeholder="Sub Title"
                className={classes.input}
                value={formData.subtitle}
                setter={(value) => handleChange("subtitle", value)}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Country
              </label>
              <Input
                type="text"
                placeholder="Country"
                className={classes.input}
                value={formData.countryName}
                setter={(value) => handleChange("countryName", value)}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Content
              </label>
              <QuillInput
                value={formData.description}
                setter={(value) => handleChange("description", value)}
              />

              <div className={classes.actions}>
                <button
                  onClick={handleSubmit}
                  className={[classes.approveBtn, classes.actionBtn].join(" ")}
                  disabled={loading == "submitData"}
                >
                  <FaCheck color="green" />
                </button>
                <button
                  type="button"
                  className={[classes.rejectBtn, classes.actionBtn].join(" ")}
                  onClick={() => navigate(-1)}
                >
                  <RxCross2 color="red" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

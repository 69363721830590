"use client";

import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../../interceptor/axiosInterceptors";
import Content from "../../atoms/Content/Content";
import JobsCard from "../../molecules/JobsCard/JobsCard";
import LoadMore from "../../molecules/LoadMore/LoadMore";
import SubHeader from "../SubHeader/SubHeader";
import { baseURL } from "../../../resources/utils/helper";
import useDebounce from "../../../resources/hooks/useDebounce";
import { RECORDS_LIMIT } from "../../../data/constants";

export default function AllJobs({ userId = "", client = false }) {
  const { accessToken } = useSelector((state) => state.authReducer);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState("");
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState(null);
  const searchDebounce = useDebounce(search, 500);

  const getData = async ({
    loadMore = false,
    PageNumber = 1,
    filters = {},
  }) => {
    setLoading("loading");

    const query = {
      ...(client && { ClientId: userId }),
      ...(!client && { ProfessionalId: userId }),
      PageSize: RECORDS_LIMIT,
      PageNumber,
      search: searchDebounce,
      ...filters,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`ProfessionalServiceJobs?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data;
      setTotalPages(data?.totalPages);
      setData((prev) => {
        const _data = data?.data || [];
        if (loadMore) return [...prev, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

  useEffect(() => {
    getData({ search: searchDebounce, filters });
  }, [searchDebounce, filters]);

  return (
    <Container className="mb-4">
      <SubHeader
        searchPlaceholder="Search by service title"
        showSearch
        showMultiFilter
        showServiceCategoryFilter
        showAmountFilter
        showStartDateFilter
        showEndDateFilter
        search={search}
        setSearch={setSearch}
        title={"Jobs"}
        {...{ filters, setFilters, loading }}
      />

      <Content
        {...{
          data,
          noDataText: "No Job Found",
          loading: loading === "loading",
        }}
      >
        <Row>
          {data?.map((item, index) => (
            <Col key={index} lg={6} md={6} sm={12}>
              <JobsCard jobs={item} client={client} />
            </Col>
          ))}
        </Row>

        <LoadMore
          {...{
            page,
            totalPages,
            loading: loading === "loadMore",
            setPage: () => {
              const _page = page + 1;
              setPage(_page);
              getData({ loadMore: true, PageNumber: _page });
            },
          }}
        />
      </Content>
    </Container>
  );
}

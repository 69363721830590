import React from "react";
import classes from "./PleaseWait.module.css";

export default function PleaseWait() {
  return (
    <div className={classes.container}>
      <div
        className={["spinner-border", classes.spinnerBorder].join(" ")}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
      <span>Please wait...</span>
    </div>
  );
}

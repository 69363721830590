import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../../interceptor/axiosInterceptors";
import { baseURL } from "../../../resources/utils/helper";
import { PRO_HEADINGS } from "../../../data/app-data";
import Content from "../../atoms/Content/Content";
import PortfolioCard from "../../molecules/PortfolioCard/PortfolioCard";
import ProfessionalClientRequest from "../ProfessionalClientRequest/ProfessionalClientRequest";
import ProfessionalVarification from "../ProfessionalVarification/ProfessionalVarification";
import classes from "./DetailsOfProfessional.module.css";

export default function DetailsOfProfessional({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const res = await Get(
      baseURL(`User/details?userId=${slug}`),
      accessToken,
      false
    );

    if (res) {
      setData(res?.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Content
      noDataText="Professional Details Not Found"
      loading={loading}
      data={data}
      dataObject={true}
    >
      <div className={classes.mainCart}>
        <PortfolioCard isEditPen={false} data={data?.data?.profile} />

        <ProfessionalVarification
          professionals={data?.data?.professionalsVerification}
          tableHeadings={PRO_HEADINGS}
        />
        <ProfessionalClientRequest client={data?.data?.clientsVerification} />
      </div>
    </Content>
  );
}

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { Post, Put } from "../../interceptor/axiosInterceptors";
import { baseURL } from "../../resources/utils/helper";
import { apiHeader } from "../../utils/helper";
import { Input } from "../../component/atoms/Input/Input";
import RenderToast from "../../component/molecules/RenderToast";
import classes from "./AddEditAdviserCategoryModal.module.css";

export default function AddEditAdviserCategoryModal({
    slug,
    show,
    setShow,
    setSelectedCategory,
    getData = () => {}
}) {
     const { accessToken } = useSelector((state) => state?.authReducer);
    
      const [loading, setLoading] = useState("");
    
      const [formData, setFormData] = useState({
        name: slug?.category || "",
        tagline: slug?.catDesc || "",
      });

      const handleChange = (name, value) => {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };
    
      const handleSubmit = async () => {
        if (!formData.name || !formData.tagline) {
          return RenderToast({ type: "error", message: "Input all fields" });
        }
    
        setLoading("submitting");
    
        const apiFunc = slug ? Put : Post;
    
        const params = {
          Id: slug && slug.id,  
          Name: formData.name,
          Tagline: formData?.tagline
        };
    
        const res = await apiFunc(
          baseURL("AdvisorPage/category-type"),
          params,
          apiHeader(accessToken)
        );

        if (res) {
            RenderToast({ type: "success", message: slug ? "Successfully Updated" : "Successfully Created" });
            setFormData("");
            setSelectedCategory(null);
            setShow(false);
            getData({ PageNumber: 1 });
        }
        setLoading("");
      };

    return (
        <>
          <style jsx>{`
            .modal-content {
              width: 100%;
              border-radius: 20px;
              overflow: hidden;
            }
            .modal-body {
              padding: 24px;
            }
            .modal-header {
              flex-direction: column;
              background: var(--black-color);
              border-bottom: none;
              padding: 0.75rem;
            }
            .name {
              font-size: 18px;
              color: var(--black-color);
            }
          `}</style>
          <Modal show={show} onHide={() => { 
            setSelectedCategory(null);
            setShow(false)}
            } centered>
            <Modal.Body>
              <h6>{slug ? `Edit` : `Add`} Category </h6>
              <div className={classes.formContainer}>
                <div className={`${classes.form} ${classes.formWidth}`}>
                  <Input
                    type="text"
                    placeholder="Category Name"
                    className={classes.input}
                    value={formData.name}
                    setter={(value) => handleChange("name", value)}
                  />
    
                  <Input
                    type="text"
                    placeholder="Tagline"
                    className={classes.input}
                    value={formData.tagline}
                    setter={(value) => handleChange("tagline", value)}
                  />
    
                  <div className={classes.actions}>
                    <button
                      onClick={handleSubmit}
                      className={[classes.approveBtn, classes.actionBtn].join(" ")}
                      disabled={loading !== ""}
                    >
                      <FaCheck color="green" />
                    </button>
                    <button
                      type="button"
                      className={[classes.rejectBtn, classes.actionBtn].join(" ")}
                      disabled={loading !== ""}
                      onClick={() => {
                        setSelectedCategory(null);                       
                        setShow(false)
                      }}
                    >
                      <RxCross2 color="red" />
                    </button>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
}

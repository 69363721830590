import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../../interceptor/axiosInterceptors";
import { baseURL } from "../../../resources/utils/helper";
import Accordian from "../../molecules/Accordian";
import Content from "../../atoms/Content/Content";
import PortfolioCard from "../../molecules/PortfolioCard/PortfolioCard";
import classes from "./ProfessionalPortfolio.module.css";

export default function ProfessionalPortfolio({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    const response = await Get(
      baseURL(`Portfolio?userId=${slug}`),
      accessToken
    );
    if (response) {
      setData(response?.data?.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Content
      noDataText="No Portfolio Found"
      loading={loading}
      data={data}
      dataObject={true}
    >
      <div className={classes.mainCart}>
        <p className="pb-3">
          <strong> My Profile </strong>
        </p>
        <PortfolioCard isEditPen={false} data={data?.profile} />
        <div className={`px-3 ${classes.proDetail}`}>
          <p>{data?.description?.description || "No description available"}</p>
        </div>
        <Accordian data={data} />
      </div>
    </Content>
  );
}

import moment from "moment";
import React from "react";
import { mergeClass } from "../../../utils/helper";
import styles from "./AchievementCard.module.css";

const AchievementCard = ({
  image,
  title,
  from,
  to,
  date,
  description,
  isImage = false,
}) => {
  return (
    <div className={styles.card}>
      {isImage && (
        <div className={styles.cardImg}>
          <img src={image} alt={title} className={styles.cardImage} />
        </div>
      )}

      <div className={styles.cardContent}>
        <h3 className={styles.cardTitle}>{title}</h3>
        {date ? (
          <p className={styles.cardDate}>{moment(date).format("ll")}</p>
        ) : (
          <p className={styles.cardDate}>{`From ${from} to ${to}`}</p>
        )}

        <p className={mergeClass(styles.cardDescription, "maxLine1")}>
          {description}
        </p>
      </div>
    </div>
  );
};

export default AchievementCard;

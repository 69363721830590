import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { Get, Post, Put } from "../../interceptor/axiosInterceptors";
import Header from "../../component/Header/Header";
import { Input } from "../../component/atoms/Input/Input";
import BackButton from "../../component/molecules/BackButton/BackButton";
import { Loader } from "../../component/molecules/Loader";
import RenderToast from "../../component/molecules/RenderToast";
import UploadImageBox from "../../component/molecules/UploadImageBox";
import SubHeader from "../../component/organisms/SubHeader/SubHeader";
import { baseURL, mediaUrl } from "../../resources/utils/helper";
import { apiHeader, mergeClass, validateEmail } from "../../utils/helper";
import classes from "./AddEditAdviser.module.css";
import ComboBox from "../../component/molecules/ComboBox/ComboBox";
import useDebounce from "../../resources/hooks/useDebounce";
import { RECORDS_LIMIT } from "../../data/constants";
import { useLocation } from "react-router-dom";
import {
  GetCountries,
  GetState,
  GetCity,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import { DropDown } from "../../component/molecules/DropDown/DropDown";

export default function AddEditAdviser() {
  const slug = useParams()?.slug;
  const { accessToken } = useSelector((state) => state?.authReducer);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const categoryId = queryParams.get("categoryId");

  const [loading, setLoading] = useState(""); //  submitData
  const [profs, setProfs] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [email, setEmail] = useState("");
  const emailDebounce = useDebounce(email);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    designation: "",
    number: "",
    email: "",
    locationCity: "",
    locationCountry: "",
    locationState: "",
    image: null,
    userId: "",
  });
  console.log("🚀 ~ AddEditAdviser ~ formData:", formData);

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const getProfs = async () => {
    const response = await Get(
      baseURL(
        `Users?userRoleId=3&PageNumber=1&PageSize=${RECORDS_LIMIT}&search=${emailDebounce}`
      ),
      accessToken,
      false
    );

    if (response) {
      setProfs(response?.data?.data);
    }
  };

  const handleAddEmailField = async ({ id = "", email = "" }) => {
    setProfs([]);

    if (!email) {
      return RenderToast({
        type: "success",
        message: "Please enter email address.",
      });
    }

    if (!validateEmail(email)) {
      return RenderToast({
        type: "error",
        message: "Please enter valid email address.",
      });
    }

    setLoading("portfolio");

    const response = await Get(baseURL(`Portfolio?userId=${id}`), accessToken);

    if (response) {
      setEmail("");
      const data = response?.data?.data;
      setFormData({
        name: data?.profile?.firstName + " " + data?.profile?.lastName,
        designation: "",
        email: data?.profile?.email,
        number: data?.profile?.phone || "N/A",
        image: data?.profile?.profileImagePath || "/images/profileDummy.jpg",
        locationCity: "",
        locationCountry: "",
        locationState: "",
        userId: data?.profile?.id,
      });
    }

    setLoading("");
  };

  const handleSubmit = async () => {
    if (
      !formData.name ||
      !formData.image ||
      !formData.designation ||
      !formData.email ||
      !formData.locationCity ||
      !formData.locationCountry ||
      !formData.locationState
    ) {
      return RenderToast({
        type: "error",
        message: "All fields are required.",
      });
    }

    if (validator.isNumeric(formData.name)) {
      return RenderToast({
        type: "error",
        message: "Name cannot be a number.",
      });
    }

    setLoading("submitData");

    const form_data = {
      UserId: formData?.userId,
      ProfessionalAdvisorCategoryId: Number(categoryId),
      Designation: formData?.designation,
      LocationCountry: formData?.locationCountry?.value,
      LocationState: formData?.locationState?.value,
      LocationCity: formData?.locationCity?.value,
      LocationCountryName: formData?.locationCountry?.label,
      LocationCityName: formData?.locationCity?.label,
      Id: slug && slug
    };

    let cusFun = slug ? Put : Post;

    const res = await cusFun(
      baseURL(`AdvisorPage/advisor`),
      form_data,
      apiHeader(accessToken, true)
    );
    if (res) {
      RenderToast({
        type: "success",
        message: `Adviser ${slug ? "updated" : "created"} successfully.`,
      });
      navigate(-1);
    }

    setLoading("");
  };

  const getData = async () => {
    const url = baseURL(`AdvisorPage?id=${slug}`);
    setLoading("getData");

    const response = await Get(url, accessToken);

    if (response) {
      const data = response?.data?.data;
      setFormData({
        name: data?.firstName + " " + data?.lastName,
        designation: data?.designation,
        number: data?.phone || "",
        email: data?.email,
        locationCity: data?.locationCity,
        locationCountry: data?.locationCountry,
        locationState: data?.locationState,
        image: mediaUrl(data?.profileImage),
        userId: data?.userId,
      });
    }

    setLoading("");
  };

  useEffect(() => {
    if (slug) getData();
  }, [slug]);

  useEffect(() => {
    GetCountries().then((result) => {
      const formattedCountries = result.map((country) => ({
        label: country.name,
        value: country.id,
      }));
      setCountriesList(formattedCountries);

      if (formData.locationCountry && slug) {
        const selectedCountry = formattedCountries.find(
          (country) => country.value === parseInt(formData.locationCountry)
        );
        if (selectedCountry) {
          handleChange("locationCountry", selectedCountry);
        }
      }
    });
    
    if (formData.locationCountry) {
      GetState(parseInt(formData.locationCountry?.value)).then((result) => {
        const formattedStates = result.map((state) => ({
          label: state.name,
          value: state.id,
        }));
        setStatesList(formattedStates);

        if (formData.locationState && slug) {
          const selectedState = formattedStates.find(
            (state) => state.value === parseInt(formData.locationState)
          );
          if (selectedState) {
            handleChange("locationState", selectedState);
          }
        }
      });
    }

    if (formData.locationCountry && formData.locationState)
      GetCity(
        parseInt(formData.locationCountry?.value),
        parseInt(formData.locationState?.value)
      ).then((result) => {
        const formattedCities = result.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setCitiesList(formattedCities);

        if (formData.locationCity && slug) {
          const selectedCity = formattedCities.find(
            (city) => city.value === parseInt(formData.locationCity)
          );
          if (selectedCity) {
            handleChange("locationCity", selectedCity);
          }
        }
      });
  }, [formData.locationCountry, formData.locationState, slug]);

  useEffect(() => {
    if (emailDebounce) {
      getProfs();
    } else {
      setProfs([]);
    }

    if (!email) {
      setProfs([]);
    }
  }, [emailDebounce]);

  if (loading !== "") {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <BackButton title={"Back"} />
      <Container>
        <Row>
          <Col>
            <SubHeader title={`Add Adviser`} showSearch={false} />
            <div className={`${classes.form}`}>
              <label className={mergeClass(classes.label, "my-3")}>
                Search Professional
              </label>
              <ComboBox
                data={profs}
                email={email}
                setEmail={setEmail}
                isCollaborationView={true}
                handleSubmit={(email) => {
                  handleAddEmailField(email);
                }}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Profile Picture
              </label>
              <UploadImageBox
                state={formData.image}
                setter={(value) => handleChange("image", value)}
                edit={false}
                disabled={true}
              />

              <label className={mergeClass(classes.label, "my-3")}>Name</label>
              <Input
                type="text"
                placeholder="Name"
                className={classes.input}
                value={formData.name}
                setter={(value) => handleChange("name", value)}
                disabled={true}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Designation
              </label>
              <Input
                type="text"
                placeholder="Designation"
                className={classes.input}
                value={formData.designation}
                setter={(value) => handleChange("designation", value)}
              />

              <label className={mergeClass(classes.label, "my-3")}>Email</label>
              <Input
                type="text"
                placeholder="Email"
                className={classes.input}
                value={formData.email}
                setter={(value) => handleChange("email", value)}
                disabled={true}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Phone Number
              </label>
              <Input
                type="text"
                placeholder="Phone Number"
                className={classes.input}
                value={formData.number}
                setter={(value) => handleChange("number", value)}
                disabled={true}
              />

              <label className={mergeClass(classes.label, "my-3")}>
                Country
              </label>
              <DropDown
                placeholder="Select Country"
                options={countriesList}
                value={formData.locationCountry}
                setter={(value) => {
                  handleChange("locationCountry", value)
                  handleChange("locationState", "")
                  handleChange("locationCity", "")
                  setStatesList([])
                  setCitiesList([])
                }}
                customStyle={classes.borderDropdown}
                lightBorder
              />

              <label className={mergeClass(classes.label, "my-3")}>State</label>
              <DropDown
                placeholder="Select State"
                options={statesList}
                value={formData.locationState}
                setter={(value) => {
                  handleChange("locationState", value)
                  handleChange("locationCity", "")
                  setCitiesList([])
                }}
                lightBorder
              />

              <label className={mergeClass(classes.label, "my-3")}>City</label>
              <DropDown
                placeholder="Select City"
                options={citiesList}
                value={formData.locationCity}
                setter={(value) => handleChange("locationCity", value)}
                lightBorder
              />

              <div className={classes.actions}>
                <button
                  onClick={handleSubmit}
                  className={[classes.approveBtn, classes.actionBtn].join(" ")}
                  disabled={loading == "submitData"}
                >
                  <FaCheck color="green" />
                </button>
                <button
                  type="button"
                  className={[classes.rejectBtn, classes.actionBtn].join(" ")}
                  onClick={() => navigate(-1)}
                >
                  <RxCross2 color="red" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { BiSolidEditAlt } from "react-icons/bi";
import { Button } from "../../atoms/Button/Button";
import classes from "./CategoryCard.module.css";

const CategoryCard = ({
  data,
  handleDelete,
  handleEdit,
  isServiceCategory = false,
}) => {
  return (
    <div className={classes.service_cards_container}>
      <div className={classes.service_card}>
        <div className={classes.card}>
          <div className={classes.subDivTitle}>
            <h5 className={"maxLine1"} title={!isServiceCategory && data?.name}>
              {isServiceCategory ? data?.serviceCategoryName : data?.name}
            </h5>
          </div>
          <div className={classes.user_icon}>
            {!isServiceCategory && (
              <BiSolidEditAlt
                size={30}
                color="#36696B"
                onClick={handleEdit}
                className={classes.editIcon}
              />
            )}
            {!isServiceCategory ? (
              <Button
                className={classes.btn}
                variant={data?.isEnabled ? "secondary" : "primary"}
                label={data?.isEnabled ? "Disable" : "Enable"}
                onClick={handleDelete}
              />
            ) : (
              <Button
                className={classes.btn}
                variant="primary"
                label="Edit Commission Value"
                onClick={handleEdit}
              />
            )}
          </div>
        </div>

        <div className={classes.subDivPercent}>
          <p>Commission</p>
          <h5>{data?.commission} %</h5>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;

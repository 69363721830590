import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Get } from "../../../interceptor/axiosInterceptors";
import { baseURL } from "../../../resources/utils/helper";
import { RECORDS_LIMIT } from "../../../data/constants";
import Content from "../../atoms/Content/Content";
import LoadMore from "../../molecules/LoadMore/LoadMore";
import classes from "./QuestionResponse.module.css";

export default function QuestionResponse({ slug = "" }) {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const getData = async ({ loadMore = false, PageNumber = 1 }) => {
    const query = {
      userId: slug,
      PageSize: RECORDS_LIMIT,
      PageNumber,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`QuestionResponse?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data;
      setData((prevData) => [...prevData, ...(data?.data || [])]);
      setTotalPages(data?.totalPages);
    }

    setLoading("");
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <Content
      {...{
        loading: loading === "loading",
        data,
        noDataText: "No Questionnaire Found",
      }}
    >
      <div>
        {data?.map((item) => {
          return (
            <>
              <p className={classes.question}>{item.question}</p>
              <p className={classes.answer}>{item.answer}</p>
            </>
          );
        })}

        <LoadMore
          {...{
            page,
            totalPages,
            loading: loading === "loadMore",
            setPage: () => {
              const _page = page + 1;
              setPage(_page);
              getData({ loadMore: true, PageNumber: _page });
            },
          }}
        />
      </div>
    </Content>
  );
}

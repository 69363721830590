import { useEffect, useRef, useState } from "react";
import { sendIcon } from "../../../constant/imagePath";
import { TextArea } from "../../atoms/TextArea";
import classes from "../ChatComponent.module.css";

export default function ChatInput({ handleSendMessage = () => {} }) {
  const [chatInput, setChatInput] = useState("");
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [chatInput]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      if (chatInput) {
        handleSendMessage(chatInput, () => setChatInput(""));
      }
    }
  };

  return (
    <div className={classes.chatInputDiv}>
      <TextArea
        value={chatInput}
        setter={setChatInput}
        placeholder="Aa"
        containerClass={`${classes.chatInputContainer} `}
        onKeyDown={handleKeyDown}
        customStyle={{
          width: "100%",
          overflow: "auto",
          resize: "none",
          fontFamily: "var(--ff-primary-reg)",
          border: "1px solid var(--light-input-border-color)",
          borderRadius: "40px",
          maxHeight: "40px",
          fontSize: "14px",
          boxSizing: "border-box",
          padding: "10px 18px",
        }}
      />
      <div>
        <img alt="sendIcon" src={sendIcon} width={24} height={24} />
      </div>
    </div>
  );
}

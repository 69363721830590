import moment from "moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineAccountCircle } from "react-icons/md";
import classes from "./ReferralCard.module.css";
export default function ReferralCard({ data, role, underLineClass, onClick }) {
  return (
    <div
      className={classes.NotificationCard}
      onClick={() => onClick && onClick()}
    >
      <div className={classes.user_div}>
        <div>
          <MdOutlineAccountCircle size={35} color="#36696B" />
        </div>
        <div className={underLineClass ? underLineClass : classes.name}>
          <h4>{data?.name}</h4>
        </div>
        <div>
          <p>
            joined the platform as a <strong>{role}</strong>
          </p>
        </div>
      </div>

      <hr />
      <div className={classes.modalFooter}>
        <div className={classes.timeDiv}>
          <p>
            {" "}
            <FaRegCalendarAlt />{" "}
            <span className={classes.time}>
              {moment(data?.time).fromNow()}
            </span>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import validator from "validator";
import { Get, Post, Put } from "../../interceptor/axiosInterceptors";
import Header from "../../component/Header/Header";
import { Input } from "../../component/atoms/Input/Input";
import BackButton from "../../component/molecules/BackButton/BackButton";
import { Loader } from "../../component/molecules/Loader";
import RenderToast from "../../component/molecules/RenderToast";
import UploadImageBox from "../../component/molecules/UploadImageBox";
import SubHeader from "../../component/organisms/SubHeader/SubHeader";
import { baseURL } from "../../resources/utils/helper";
import { apiHeader, createFormData, mergeClass } from "../../utils/helper";
import classes from "./AddEditProtectionSolution.module.css";

export default function Page() {
  const slug = useParams()?.slug;
  const pathname = useLocation();
  const params = pathname?.search;
  const { accessToken } = useSelector((state) => state?.authReducer);

  const [loading, setLoading] = useState(""); // getData, submitData
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    LandingPageDetailTypeId: params.endsWith("education") ? 3 : 2,
    title: "",
    description: "",
    icon: null,
  });

  const handleChange = (name, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formData.title || !formData.description || !formData.icon) {
      return RenderToast({
        type: "error",
        message: "All fields are required, including images.",
      });
    }

    if (validator.isNumeric(formData.title)) {
      return RenderToast({
        type: "error",
        message: "Title cannot be a number.",
      });
    }

    setLoading("submitData");

    const form_data = createFormData(formData);
    let cusFun = slug ? Put : Post;
    const res = await cusFun(
      baseURL(`LandingPageDetail`),
      form_data,
      apiHeader(accessToken, true)
    );
    if (res) {
      RenderToast({
        type: "success",
        message: `${
          params.endsWith("education")
            ? "Advanced Education"
            : "Protection Solution"
        } ${slug ? "Updated" : "Created"} successfully.`,
      });
      navigate(-1);
    }

    setLoading("");
  };

  const getData = async () => {
    const url = baseURL(`LandingPageDetail/single?id=${slug}`);
    setLoading("getData");

    const response = await Get(url, accessToken);

    if (response) {
      const data = response?.data?.data;
      setFormData({
        LandingPageDetailTypeId: params.endsWith("education") ? 3 : 2,
        title: data?.title,
        description: data?.description,
        icon: data?.icon,
        id: data?.id,
      });
    }

    setLoading("");
  };
  useEffect(() => {
    if (slug) getData();
  }, []);

  if (loading == "getData") {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <BackButton title={"Back"} />
      <Container className="mb-5">
        <Row>
          <Col>
            <SubHeader
              title={`${slug ? "Edit" : "Add"} ${
                params.endsWith("education")
                  ? "Advanced Education"
                  : "Protection Solution"
              }`}
              showSearch={false}
            />
            <div className={`${classes.form}`}>
              <label className={mergeClass(classes.label, "my-4")}>
                Upload Icon
              </label>
              <UploadImageBox
                state={formData.icon}
                setter={(value) => handleChange("icon", value)}
              />
              <Input
                type="text"
                placeholder="Title"
                className={mergeClass(classes.input, "mt-4")}
                value={formData.title}
                setter={(value) => handleChange("title", value)}
              />

              <Input
                type="text"
                placeholder="Description"
                className={classes.input}
                value={formData.description}
                setter={(value) => handleChange("description", value)}
              />

              <div className={classes.actions}>
                <button
                  onClick={handleSubmit}
                  className={[classes.approveBtn, classes.actionBtn].join(" ")}
                  disabled={loading == "submitData"}
                >
                  <FaCheck color="green" />
                </button>
                <button
                  type="button"
                  className={[classes.rejectBtn, classes.actionBtn].join(" ")}
                  onClick={() => navigate(-1)}
                >
                  <RxCross2 color="red" />
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

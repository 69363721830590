import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../interceptor/axiosInterceptors";
import AllJobs from "../../component/organisms/AllJobs/AllJobs";
import BackButton from "../../component/molecules/BackButton/BackButton";
import ChatComponent from "../../component/chatComponent";
import Content from "../../component/atoms/Content/Content";
import Header from "../../component/Header/Header";
import { Loader } from "../../component/molecules/Loader";
import PortfolioCard from "../../component/molecules/PortfolioCard/PortfolioCard";
import SessionCard from "../../component/molecules/SessionCard/SessionCard";
import TabsComponent from "../../component/molecules/TabsComponent/TabsComponent";
import { baseURL } from "../../resources/utils/helper";
import { SESSIONS_TAB_DATA, USER_TABS } from "../../data/mock-data";
import classes from "./UserDetail.module.css";

export default function UserDetail() {
  const { accessToken } = useSelector((state) => state?.authReducer);

  const params = useParams();
  const slug = params?.slug;

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(USER_TABS[0]?.value);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleTabChange = (tabValue) => {
    setSelectedTab(tabValue);
  };

  const getUser = async () => {
    setLoading(true);
    const res = await Get(baseURL(`User?id=${slug}`), accessToken);
    if (res) {
      setData(res?.data?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUser();
  }, []);

  const renderContent = () => {
    if (selectedTab === USER_TABS[0]?.value)
      return (
        <div className={classes.mainCart}>
          <Content
            dataObject
            {...{ loading, data, noDataText: "No Data Found" }}
          >
            <PortfolioCard showButton="true" isEditPen={false} data={data} />
          </Content>
        </div>
      );

    if (selectedTab === "jobs") return <AllJobs client userId={slug} />;

    if (selectedTab === USER_TABS[2]?.value)
      return <ChatComponent slug={slug} />;

    if (selectedTab === USER_TABS[3]?.value)
      return (
        <div className={classes.servicesTabDiv}>
          <Row>
            {Array(8)
              ?.fill(SESSIONS_TAB_DATA)
              ?.map((e, i) => (
                <Col md={6} key={i}>
                  <SessionCard
                    onClick={() => navigate(`/session/detail/${i}`)}
                    data={e}
                    showDate
                    showAmount
                    buttonLabel={"View Details"}
                  />
                </Col>
              ))}
          </Row>
        </div>
      );
  };

  return (
    <div className={classes.mainDiv}>
      <Header />
      <BackButton title="Back" profile leftIcon={<FaArrowLeft size={18} />} />
      <Container>
        <div className={classes.main}>
          <TabsComponent
            tab={selectedTab}
            setTab={handleTabChange}
            tabs={USER_TABS}
          />
          {loading ? <Loader /> : renderContent()}
        </div>
      </Container>
    </div>
  );
}

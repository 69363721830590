import React from "react";
import { Button } from "../../atoms/Button/Button";
import classes from "./LoadMore.module.css";

export default function LoadMore({
  loading = false,
  setPage = () => {},
  page = 1,
  totalPages = 1,
}) {
  if (totalPages <= page && !loading) return null;

  return (
    <div className="loadMoreBtn">
      <Button
        variant="secondary"
        disabled={loading}
        onClick={() => setPage((prev) => prev + 1)}
        className={classes.btn}
      >
        {loading ? "Loading..." : "Load More"}
      </Button>
    </div>
  );
}

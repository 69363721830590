import { config } from "../../config";
import axios from "axios";
import moment from "moment";
import "moment-timezone";
import RenderToast from "../../component/molecules/RenderToast";
import { CURRENCY_SYMBOL } from "../../data/constants";

export const getFormattedPrice = (price = 0, symbol = true) => {
  return `${symbol ? CURRENCY_SYMBOL : ""} ${parseFloat(price).toFixed(2)}`;
};

export const mergeClass = (...classes) => {
  return classes.join(" ");
};

export const capitalizeFirstLetter = (l) =>
  l.charAt(0).toUpperCase() + l.slice(1);

export const formatNumber = (num) => {
  if (num >= 1000) {
    var suffixes = ["", "k", "m", "b", "t"];
    var suffixNum = Math.floor(("" + num).length / 3);
    var shortValue = parseFloat(
      (suffixNum !== 0 ? num / Math.pow(1000, suffixNum) : num).toPrecision(2)
    );
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  }
  return num;
};

export function numberWithCommas(x = 0) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const createFormData = (data) => {
  const formData = new FormData();
  for (let key in data) {
    if (Array.isArray(data[key])) {
      for (let d in data[key]) {
        if (typeof data[key][d] == "string") {
          formData.append(key, data[key][d]);
        } else if (
          data[key][d] instanceof File ||
          data[key][d] instanceof Date
        ) {
          formData.append(key, data[key][d]);
        } else {
          formData.append(key, JSON.stringify(data[key][d]));
        }
      }
    } else if (typeof data[key] == "object") {
      if (data[key] instanceof File) {
        formData.append(key, data[key]);
      } else {
        formData.append(key, JSON.stringify(data[key]));
      }
    } else {
      formData.append(key, data[key]);
    }
  }
  return formData;
};

export const apiHeader = (token, isFormData) => {
  if (token && !isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  }
  if (token && isFormData) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
  }
  if (!token && !isFormData) {
    return {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }

  if (!token && isFormData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  }
};

export const makeWordPlural = (word = "", len = 0, concat = "s") => {
  return `${word}${len > 1 ? concat : ""}`;
};

export const uploadToPresignedUrl = async (
  presignedUrl,
  selectedFile,
  setUploadProgress
) => {
  try {
    return await axios.put(presignedUrl, selectedFile, {
      headers: {
        "Content-Type": selectedFile.type,
      },

      onUploadProgress: (data) => {
        const progress = Math.round((100 * data.loaded) / data.total);
        setUploadProgress((prev) => (prev >= progress ? prev : progress));
      },
    });
  } catch (error) {
    return null;
  }
};

// Output: 00:00
export const formatMinutes = (minutes = 0) => {
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes =
    remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes;

  const time24Hour = formattedHours + ":" + formattedMinutes;

  return time24Hour;
};

// Output: 0 hr 0 mints
export const formatMinutes2 = (item = null) => {
  const topicDuration = item?.topics?.reduce(
    (acc, topic) => acc + topic.duration,
    0
  );
  if (topicDuration > 59) {
    const hours = Math.floor(topicDuration / 60);
    const minutes = topicDuration % 60;
    return `${hours} hr ${minutes} min`;
  } else {
    return `${topicDuration} minutes`;
  }
};

export const formatMinutes3 = (topicDuration = 0) => {
  if (topicDuration > 59) {
    const hours = Math.floor(topicDuration / 60);
    const minutes = topicDuration % 60;
    if (minutes > 0)
      return `${hours} ${makeWordPlural(
        "hour",
        hours
      )} ${minutes} ${makeWordPlural("minute", minutes)}`;
    else return `${hours} ${makeWordPlural("hour", hours)}`;
  } else {
    return `${topicDuration} ${makeWordPlural("minute", topicDuration)}`;
  }
};

export const formatMinutes4 = (topicDuration = 0) => {
  if (topicDuration > 59) {
    const hours = Math.floor(topicDuration / 60);
    const minutes = topicDuration % 60;
    return `${hours} hr ${minutes} min`;
  } else {
    return `${topicDuration} minutes`;
  }
};

export async function FileDownloadFromUrl(
  url,
  fileName,
  token = "",
  setLoading = () => {}
) {
  setLoading(true);
  axios({
    url: url,
    responseType: "blob",
    method: "GET",
    ...apiHeader(token),
  })
    .then((res) => {
      const href = URL.createObjectURL(res.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      setLoading(false);
    })
    .catch((e) => {
      setLoading(false);
      RenderToast({
        type: "error",
        message: `No Data for Downloading.`,
      });
    });
}
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePassword = (password) => {
  const errors = [];

  // Check minimum length
  if (password.length < 8) {
    errors.push("Password must be at least 8 characters long.");
  }

  // Check for lowercase letter
  if (!/[a-z]/.test(password)) {
    errors.push("Password must contain at least one lowercase letter.");
  }

  // Check for uppercase letter
  if (!/[A-Z]/.test(password)) {
    errors.push("Password must contain at least one uppercase letter.");
  }

  // Check for digit
  if (!/\d/.test(password)) {
    errors.push("Password must contain at least one digit.");
  }

  if (!/[@$!%*?&-]/.test(password)) {
    errors.push(
      "Password must contain at least one special character (@$!%*?&-)."
    );
  }

  return errors;
};

// export const handleLogin = (dispatch, token) => {
//   const encryptData = handleEncrypt(token);
//   let tokenDecoded = parseJwt(token);

//   if (tokenDecoded?.role == "3") {
//     tokenDecoded = {
//       isRegistrationVerified:
//         tokenDecoded?.isRegistrationCompleted?.toLowerCase() === "true",
//       isClientVerified:
//         tokenDecoded?.IsClientCompleted?.toLowerCase() === "true",
//       isProfessionalVerified:
//         tokenDecoded?.IsProfessionalCompleted?.toLowerCase() === "true",
//       isStripeVerified:
//         tokenDecoded?.IsStripeCompleted?.toLowerCase() === "true",
//     };

//     const isVerified =
//       tokenDecoded?.isStripeVerified && tokenDecoded?.isProfessionalVerified;

//     if (isVerified) {
//       Cookies.set("isVerified", isVerified);
//     }

//     Cookies.set("isSubscribed", tokenDecoded?.IsSubscribed);
//   }

//   if (tokenDecoded?.role == "2") {
//     Cookies.set("isQuestionnaireFilled", tokenDecoded?.IsQuestionnaireFilled);
//   }

//   Cookies.set("_xpdx", encryptData);
//   dispatch(updateToken(token));
// };

export function getInitials(fullName) {
  const nameParts = fullName?.split(" ");
  const initials = nameParts
    ?.map((part) => part?.charAt(0)?.toUpperCase())
    ?.join("")
    ?.slice(0, 2);

  return initials;
}
export function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

export const getYearsOption = (startYear = 1964) => {
  const years = [];

  for (let year = startYear; year <= new Date().getFullYear(); year++) {
    years.push({ value: year.toString(), label: year.toString() });
  }

  return years.reverse();
};

export const getLocalTime = (date = "") => {
  return moment(moment.utc(date).format()).fromNow();
};

export const removeNullValues = (value, fallback = "") => {
  if (!value || value === "null") return fallback;
  return value;
};

export const getNA = (item = null) => {
  return item || "N/A";
};

export function convertUtcToUserTime(utcTime) {
  // Append a default date to the time for complete ISO string
  const defaultDate = "1970-01-01"; // You can use any date, as long as it's consistent
  const utcTimeWithDate = `${defaultDate}T${utcTime}:00Z`;

  // Detect the user's timezone if not provided
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Parse the UTC time as a moment object
  const timeInUtc = moment.utc(utcTimeWithDate);

  // Convert the UTC time to the user's timezone
  const timeInUserTimezone = timeInUtc.tz(timezone);

  // Format and return the time
  return timeInUserTimezone.format("HH:mm");
}

export function extractQueryParams(searchParams, keys) {
  const params = {};

  keys.forEach((key) => {
    const search = searchParams.get(key);
    if (search) {
      params[key] = search;
    }
  });

  return params;
}

export const baseURL = (link) => `${config?.apiBaseUrl}/api/${link}`;
export const mediaUrl = (url) => (url ? url : "/images/big-avatar.png");
export const imageUrl = (url) => {
  if (!url) return "";
  if (url.startsWith("/")) return url;
  const result = url.indexOf("http");
  const imageRenderUrl =
    result === -1 ? `${config?.apiBaseUrl}/media/${url}` : url;
  return imageRenderUrl;
};

import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Get } from "../../interceptor/axiosInterceptors";
import Content from "../../component/atoms/Content/Content";
import Header from "../../component/Header/Header";
import LoadMore from "../../component/molecules/LoadMore/LoadMore";
import ProfileNameEmail from "../../component/molecules/ProfileNameEmail/ProfileNameEmail";
import UserNotificationCard from "../../component/molecules/UserNotificationCard/UserNotificationCard";
import { baseURL } from "../../resources/utils/helper";
import { RECORDS_LIMIT } from "../../data/constants";
import classes from "./ContactsView.module.css";

export default function ContactsView() {
  const { accessToken } = useSelector((state) => state.authReducer);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(""); // loading loadMore

  const getData = async ({ loadMore = false, PageNumber = 1 }) => {
    setLoading("loading");

    const query = {
      PageSize: RECORDS_LIMIT,
      PageNumber,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const response = await Get(
      baseURL(`Contacts?${queryParams}`),
      accessToken,
      false
    );

    if (response) {
      const data = response?.data;
      setTotalPages(data?.totalPages);
      setData((prev) => {
        const _data = data?.data || [];
        if (loadMore) return [...prev, ..._data];
        return _data;
      });
    }

    setLoading("");
  };

  useEffect(() => {
    getData({});
  }, []);

  return (
    <div>
      <Header />
      <ProfileNameEmail />
      <Container>
        <div className={classes.notification_section}>
          <h5 className={classes.notification_heading}>Contacts</h5>
          <Content
            {...{
              data,
              noDataText: "No Contact Found",
              loading: loading === "loading",
            }}
          >
            <Row className="pb-5">
              {data?.map((contact, index) => (
                <UserNotificationCard
                  key={index}
                  name={contact?.firstName + contact?.lastName}
                  email={contact?.email}
                  message={contact?.message}
                />
              ))}
            </Row>

            <LoadMore
              {...{
                page,
                totalPages,
                loading: loading === "loadMore",
                setPage: () => {
                  const _page = page + 1;
                  setPage(_page);
                  getData({ loadMore: true, PageNumber: _page });
                },
              }}
            />
          </Content>
        </div>
      </Container>
    </div>
  );
}

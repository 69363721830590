import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Get } from "../../interceptor/axiosInterceptors";
import AllJobs from "../../component/organisms/AllJobs/AllJobs";
import BackButton from "../../component/molecules/BackButton/BackButton";
import Categories from "../../component/organisms/Categories/Categories";
import chatComponent from "../../component/chatComponent";
import Content from "../../component/atoms/Content/Content";
import DetailsOfProfessional from "../../component/organisms/DetailsOfProfessional/DetailsOfProfessional";
import Header from "../../component/Header/Header";
import LoadMore from "../../component/molecules/LoadMore/LoadMore";
import Pagination from "../../component/molecules/PaginationComponent";
import ProfessionalPortfolio from "../../component/organisms/ProfessionalPortfolio/ProfessionalPortfolio";
import QuestionResponse from "../../component/organisms/QuestionResponse";
import ReferralsComponent from "../../component/organisms/ReferralsComponent";
import ReviewsCard from "../../component/molecules/ReviewsCard/ReviewsCard";
import Services from "../../component/organisms/Services/Services";
import SessionCard from "../../component/molecules/SessionCard/SessionCard";
import SubHeader from "../../component/organisms/SubHeader/SubHeader";
import TabsComponent from "../../component/molecules/TabsComponent/TabsComponent";
import { baseURL } from "../../resources/utils/helper";
import { PRO_TABS } from "../../data/app-data";
import { RECORDS_LIMIT } from "../../data/constants";
import { SESSIONS_TAB_DATA } from "../../data/mock-data";
import useDebounce from "../../resources/hooks/useDebounce";
import classes from "./ProfessionalsDetail.module.css";

export default function ProfessionalsDetail() {
  const navigate = useNavigate();

  const { accessToken } = useSelector((state) => state?.authReducer);

  const params = useParams();
  const slug = params?.slug;

  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(null);
  const [search, setSearch] = useState("");
  const searchDebounce = useDebounce(search, 500);
  const [totalPages, setTotalPages] = useState(0);

  const [tab, setTab] = useState(PRO_TABS[0]?.value);

  const getReviews = async ({
    PageNumber = 1,
    loadMore = false,
    filters = {},
  }) => {
    const query = {
      ProfessionalId: slug,
      PageSize: RECORDS_LIMIT,
      ...filters,
      ...(PageNumber && { PageNumber }),
      search: searchDebounce,
    };

    let queryParams = new URLSearchParams(query);
    queryParams = queryParams?.toString();

    setLoading(loadMore ? "loadMore" : "loading");

    const res = await Get(
      baseURL(`Reviews?${queryParams}`),
      accessToken,
      false
    );
    if (res) {
      const data = res?.data;
      setTotalPages(data?.totalPages);
      setReviews((prev) => {
        const _data = data?.data || [];
        if (loadMore) return [..._data, ...prev];
        return _data;
      });
    }

    setLoading(false);
  };

  const renderContent = () => {
    if (tab === "details") return <DetailsOfProfessional slug={slug} />;

    if (tab === "portfolio") return <ProfessionalPortfolio slug={slug} />;

    if (tab === "questionnaires")
      return (
        <div className={classes.mainCart}>
          <p className="pb-3">
            <strong className={classes.heading}>Questionnaire</strong>
          </p>
          <div>
            <QuestionResponse slug={slug} />
          </div>
        </div>
      );

    if (tab === "services") {
      return <Services slug={slug} />;
    }

    if (tab === "jobs") {
      return <AllJobs userId={slug} client={false} />;
    }

    if (tab === "chats") {
      return <chatComponent slug={slug} />;
    }

    if (tab === "referrals") {
      return (
        <>
          <ReferralsComponent slug={slug} setTab={setTab} />
        </>
      );
    }
    if (tab === "reviews") {
      return (
        <>
          <SubHeader
            showSearch
            showMultiFilter
            filters={filters}
            setSearch={setSearch}
            showStartDateFilter
            showEndDateFilter
            showServiceCategoryFilter={true}
            showRatingFilter={true}
            search={search}
            {...{
              setFilters,
            }}
          />
          <Content
            {...{
              loading: loading === "loading",
              data: reviews,
              noDataText: "No Reviews Found",
            }}
          >
            {" "}
            <Row className="gy-4">
              {reviews?.map((e, i) => (
                <Col md={12} key={i}>
                  <ReviewsCard reviews={e} />
                </Col>
              ))}
            </Row>
            <LoadMore
              {...{
                page,
                totalPages,
                loading: loading === "loadMore",
                setPage: () => {
                  const _page = page + 1;
                  setPage(_page);
                  getReviews({ loadMore: true, PageNumber: _page });
                },
              }}
            />
          </Content>
        </>
      );
    }

    if (tab === "sessions") {
      return (
        <>
          <Row>
            {Array(9)
              ?.fill(SESSIONS_TAB_DATA)
              ?.map((e, i) => (
                <Col key={i} md={4}>
                  <SessionCard
                    onClick={() => navigate(`/session/detail/${i}`)}
                    data={e}
                    showDate
                    showAmount
                    buttonLabel={"View Details"}
                  />
                </Col>
              ))}
          </Row>
          <Row>
            <Col md={12}>
              <Pagination currentPage={1} totalPages={15} />
            </Col>
          </Row>
        </>
      );
    }

    if (tab === "categories") {
      return <Categories slug={slug} />;
    }
  };

  useEffect(() => {
    if (tab === "reviews") {
      getReviews({
        filters,
        search: searchDebounce,
      });
    }
  }, [tab, filters, searchDebounce]);

  return (
    <div>
      <Header />
      <BackButton title="Back" profile leftIcon={<FaArrowLeft size={18} />} />
      <Container>
        <div className={classes.main}>
          <TabsComponent tab={tab} setTab={setTab} tabs={PRO_TABS} />
          {renderContent()}
        </div>
      </Container>
    </div>
  );
}

import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import classes from "./TabsComponent.module.css";

const TabsComponent = ({ tab = null, setTab = () => {}, tabs = [] }) => {
  const handleTabSelect = (tabValue) => {
    setTab(tabValue);
  };

  return (
    <>
      <div className={classes.proDetailTab}>
        <Tabs
          activeKey={tab}
          onSelect={handleTabSelect}
          transition={false}
          id="proTab"
          className={`mb-3 ${classes.tabBtn}`}
        >
          {tabs?.map((item) => (
            <Tab
              eventKey={item.value}
              title={item.label}
              key={item.value}
              onClick={() => setTab(item.value)}
              tabClassName={tab === item.value ? "active-tab" : ""}
            ></Tab>
          ))}
        </Tabs>
      </div>
    </>
  );
};

export default TabsComponent;
